import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutActions } from "../../../redux/context/actions";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { ResetPasswordContainer } from "../../../views/global/ResetPassword/resetPasswordContainer";
import { taskRoute } from "../../../core/constants/routesPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import Logout from "../Logout";
import NotificationSettingsContainer from "../../../views/global/NotificationSettings/NotificationSettingsContainer";
import { NotificationSettingsApi } from "../../../core/api/notificationSettingsApi";
import isEmpty from "../../../core/helpers/isEmpty";
const SettingsDropdown = () => {
    var _a, _b, _c;
    const [currentKey] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [key] = useState(0);
    const [notificationSettingsModal, setNotificationSettingsModal] = useState(false);
    const [taskCount] = useState(undefined);
    const context = useSelector((state) => {
        return state.context;
    });
    const handleClick = () => {
        dispatch(logoutActions(history));
    };
    const onChangePassword = () => {
        setChangePasswordModal(true);
    };
    const changePasswordModalHandler = () => {
        setChangePasswordModal(false);
    };
    const notificationSettingsModalClose = () => {
        setNotificationSettingsModal(false);
    };
    const [settings, setSettings] = useState([]);
    const notificationSettingsModalOpen = () => {
        NotificationSettingsApi.getNotificationSettings().then((res) => {
            setNotificationSettingsModal(true);
            console.log({ res });
            if (!isEmpty(res)) {
                setSettings(res);
            }
        });
    };
    const accountMenu = (React.createElement(Menu, { selectedKeys: [currentKey], style: { minWidth: "180px" }, className: "account-menu" },
        taskCount !== undefined && taskCount !== 0 && (React.createElement(Menu.Item, { key: "task", className: "action-item", onClick: () => {
                history.push(taskRoute);
            } },
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faTasks, size: "sm" }),
                " ",
                ` You have ${taskCount} due task`))),
        SessionStorage.getKey(SessionVariables.PASSWORD_EXPIRES_IN) && (React.createElement(Menu.Item, { key: "password-expiry-message", className: "action-item", style: { fontStyle: "italic", color: "#ffcc00", cursor: "auto" } },
            React.createElement("span", { className: "icofont-warning", key: key }, ` ${SessionStorage.getKey((_a = SessionVariables.PASSWORD_EXPIRES_IN) !== null && _a !== void 0 ? _a : "")}`))),
        React.createElement(Menu.Item, { key: "change-password", className: "action-item", onClick: onChangePassword },
            React.createElement("span", { className: "icofont-ui-password", key: key },
                `  Change Password`,
                " ")),
        React.createElement(Menu.Item, { key: "notification-settings", className: "action-item", onClick: notificationSettingsModalOpen },
            React.createElement("span", { className: "icofont-settings-alt", key: key },
                `  Notification Settings`,
                " ")),
        React.createElement(Menu.Item, { key: "logout", className: "action-item", onClick: handleClick },
            React.createElement("span", { className: "icofont-logout" },
                " ",
                `  Log Out`))));
    const stateParams = (_b = history.location.state) === null || _b === void 0 ? void 0 : _b.params;
    const parameters = stateParams && new URLSearchParams(stateParams);
    const isNotiRedirect = parameters && ((_c = !!parameters.get("isNotification")) !== null && _c !== void 0 ? _c : false);
    useEffect(() => {
        if (isNotiRedirect &&
            history.location.pathname === "/vertical/notifications-events") {
            notificationSettingsModalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { overlay: accountMenu, trigger: ["click"], placement: "bottomRight" },
            React.createElement("div", { className: "item" },
                React.createElement("span", null,
                    React.createElement("strong", null, context.userFullName)),
                React.createElement("span", { className: "icofont-simple-down" }))),
        React.createElement(NotificationSettingsContainer, { notificationSettingsModal: notificationSettingsModal, notificationSettingsModalClose: notificationSettingsModalClose, modalTitle: "Notification Settings", notificationSettings: settings, setNotificationSettings: setSettings, modalDescription: "Change Notification Settings" }),
        React.createElement(ResetPasswordContainer, { changePasswordModal: changePasswordModal, setChangePasswordModal: changePasswordModalHandler, modalTitle: "Change Password", modalDescription: "Change your password that suits you well" }),
        React.createElement(Logout, null)));
};
export default SettingsDropdown;
