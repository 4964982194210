import React, { useState } from "react";
import { Button, Input } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { LoginOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import localize from "../../../../core/utils/translation-files";
import { forgetPasswordRoute } from "../../../../core/constants/routesPath";
import { inputDefaultLength } from "../../../../core/utils/default-length";
import "./login.scss";
const initialState = {
    username: "",
    password: "",
};
const LoginForm = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const LoginSchema = Yup.object({
        username: Yup.string()
            .min(2, localize(["minimum", "Login.2", "characters"]))
            .required(localize(["Login.username", "isRequired!"])),
        password: Yup.string()
            .required(localize(["password", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
    });
    const login = useFormik({
        initialValues: initialState,
        validationSchema: LoginSchema,
        onSubmit: (values, { setErrors, resetForm }) => {
            props.LoginHandler(values, setErrors);
        },
    });
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (React.createElement("div", null,
        React.createElement("h4", { className: "mt-0 mb-1" }, localize(["Login.loginForm"])),
        React.createElement("p", { className: "text-color-200" }, localize(["Login.loginToAccessYourAccount"])),
        React.createElement("form", { className: "mb-1 loginForm", name: "basic", onSubmit: login.handleSubmit },
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Login.username"]), type: "text", name: "username", value: login.values.username, onChange: login.handleChange, className: `${login.errors["invalid"] ? "error-border" : null} ${login.errors.username && login.touched.username
                        ? "input-error"
                        : null}` }),
                login.errors.username && login.touched.username && (React.createElement("p", { className: "error" }, login.errors.username))),
            React.createElement("div", { className: "form-group login-password" },
                React.createElement(Input, { placeholder: localize(["password"]), type: showPassword ? "text" : "password", name: "password", suffix: React.createElement("span", { className: "eye-toggler", onClick: togglePasswordVisibility }, showPassword ? React.createElement(EyeTwoTone, null) : React.createElement(EyeInvisibleOutlined, null)), value: login.values.password, onChange: login.handleChange, className: `${login.errors["invalid"] ? "error-border" : null} ${login.errors.password && login.touched.password
                        ? "input-error"
                        : null}` }),
                login.errors.password && login.touched.password && (React.createElement("p", { className: "error" }, login.errors.password))),
            login.errors["invalid"] && (React.createElement("p", { className: "error" }, login.errors["invalid"])),
            React.createElement("div", { className: "form-group" },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LoginOutlined, { style: { fontSize: "1.3rem" } }) }, localize(["Button.submit"])))),
        React.createElement("br", null),
        React.createElement(Link, { to: forgetPasswordRoute }, localize(["Login.forgotYourPassword"]))));
};
export default LoginForm;
