var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import { loginRoute } from "./core/constants/routesPath";
import { ApiErrorHandler } from "./layout/components/errors/apiErrorHandler";
import Loader from "./layout/components/loading/loader";
import { NotificationContainer } from "./layout/components/notifications";
import UserRoutes from "./layout/components/routes/UserRoutes";
// Import markerSDK from Marker.io browser package
import markerSDK from "@marker.io/browser";
function App() {
    useEffect(() => {
        const loadMarkerWidget = () => __awaiter(this, void 0, void 0, function* () {
            try {
                // Load Marker.io widget
                const widget = yield markerSDK.loadWidget({
                    project: "668cfc7cd6ff859aa760d6a1",
                });
            }
            catch (error) {
                console.error("Error loading Marker.io widget:", error);
                // Handle error loading widget
            }
        });
        loadMarkerWidget();
    }, []); // Empty dependency array ensures this runs only once when the component mounts
    return (React.createElement(ApiErrorHandler, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/", exact: true },
                React.createElement(Redirect, { to: loginRoute })),
            React.createElement(UserRoutes, null)),
        React.createElement(NotificationContainer, null),
        React.createElement(Loader, null)));
}
export default App;
