import React, { useContext } from "react";
import { inAppNotificationScrollPosition } from "../../../../../../core/constants/tableScrollConstants";
import { InAppStatus } from "../../../../../../core/enums/InAppNotifications";
import { printContext } from "../../../../../../core/contexts/printContext";
import CustomTable from "../../../../../../layout/components/customTable";
import { Button } from "antd";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import localize from "../../../../../../core/utils/translation-files";
import CustomTextHighlighter from "../../../../../../layout/components/customTextHighlighter";
import { defaultDateTimeFormat } from "../../../../../../core/constants/DateTime";
import moment from "moment";
import { decryptData } from "../../../../../../core/utils/encryptionUtils";
const InAppNotificationTable = ({ data, paginationProps, handleRowClick, flagNotification, unflagNotification, readNotification, unreadNotification, paginationSection, }) => {
    const { nodeRef } = useContext(printContext);
    const columns = [
        {
            fieldName: "actions",
            title: ["Notification.read/unread"],
            align: "center",
            width: 70,
            render: (item) => (React.createElement(Button, { className: "listingIcon", onClick: (event) => {
                    event.stopPropagation();
                    (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.read
                        ? unreadNotification(item.id)
                        : readNotification(item.id);
                } },
                React.createElement(RenderSvg, { icon: (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.read
                        ? "readNotification"
                        : "unreadNotification", tooltipTitle: [
                        (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.read
                            ? "Notification.markAsUnread"
                            : "Notification.markAsRead",
                    ] }))),
        },
        {
            fieldName: "actions",
            title: ["Notification.flagged"],
            align: "center",
            width: 80,
            render: (item) => (React.createElement(RenderSvg, { tooltipTitle: localize([
                    (item === null || item === void 0 ? void 0 : item.flagged) ? "Notification.flag" : "Notification.unflag",
                ]), icon: `${(item === null || item === void 0 ? void 0 : item.flagged) ? "RatingFlagFilled" : "RatingFlag"}`, style: {
                    fontSize: "1.7em",
                    color: "#6e6b7b",
                }, onClick: (event) => {
                    event.stopPropagation();
                    (item === null || item === void 0 ? void 0 : item.flagged) === true
                        ? unflagNotification(item === null || item === void 0 ? void 0 : item.id)
                        : flagNotification(item === null || item === void 0 ? void 0 : item.id);
                } })),
        },
        {
            fieldName: "createdDate",
            title: ["Notification.DateTime"],
            sortable: true,
            width: 200,
            render: (createdDate, item) => (React.createElement("span", { style: (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.unread
                    ? { fontWeight: "bold" }
                    : { fontWeight: "lighter" } },
                " ",
                moment.utc(createdDate).format(defaultDateTimeFormat))),
        },
        {
            fieldName: "subject",
            title: ["Notification.notification"],
            width: 500,
            render: (subject, item) => (React.createElement("span", { style: (item === null || item === void 0 ? void 0 : item.inAppStatus) === InAppStatus.unread
                    ? { fontWeight: "bold" }
                    : { fontWeight: "lighter" } },
                React.createElement(CustomTextHighlighter, { text: decryptData(subject) }))),
        },
    ];
    return (React.createElement("div", { className: "customListing", "data-test": "global-notifications-listing-table", ref: nodeRef },
        React.createElement(CustomTable, { data: data, 
            // rowClassName={(record) =>
            //   record.inAppStatus === InAppStatus.unread && "unreadNotification"
            // }
            columns: columns, dataType: "any", scrollPosition: inAppNotificationScrollPosition, serverPagination: paginationProps.triggerPagination, serverSorting: true, scroll: false, serverSearching: true, totalCount: paginationProps.totalCount, currentPage: paginationProps.currentPage, pageSize: paginationProps.pageSize, rowClickHandler: handleRowClick, stateParams: true, paginationSection: paginationSection, searchAllColumn: false })));
};
export default InAppNotificationTable;
