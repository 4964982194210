import React from "react";
import "./publicLayout.scss";
import Logo from "../components/logo/logo";
import LogoPng from "../../assets/img/logo.png";
import LoginText from "../../views/global/login/components/loginText";
import loginBgImage from "../../../public/content/LoginBG.png";
const PublicLayout = ({ children, bgImg, transparent = false }) => (React.createElement("div", { className: "public-layout", style: { backgroundImage: `url(${loginBgImage})` } },
    React.createElement("div", { className: `content-box ${transparent ? "transparent" : null}` },
        React.createElement("div", { className: "content-header" },
            React.createElement(Logo, { src: LogoPng })),
        React.createElement("div", { className: "content-body" }, children)),
    React.createElement("div", { className: "login-text-detail" },
        React.createElement(LoginText, null))));
export default PublicLayout;
