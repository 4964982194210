import React from "react";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
const RatingAccuracy = ({ data }) => {
    return (React.createElement("div", null,
        React.createElement(RenderSvg, { icon: "DataQualityFlag", tooltipTitle: [
                `${+(data === null || data === void 0 ? void 0 : data.ratingAccuracy) < +(data === null || data === void 0 ? void 0 : data.accuracyThreshold)
                    ? "TrackedItem.unreliableRatingAccuracy"
                    : "TrackedItem.ratingAccuracy"}`,
                `${Math.trunc(+(data === null || data === void 0 ? void 0 : data.ratingAccuracy))}%`,
                "TrackedItem.complete",
            ], style: { color: data === null || data === void 0 ? void 0 : data.accuracyScale, marginRight: "2px" } })));
};
export default RatingAccuracy;
