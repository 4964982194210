import React from "react";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
import localize from "../../../../../../../core/utils/translation-files";
import { notificationEventsRoute } from "../../../../../../../core/constants/routesPath";
import { useHistory } from "react-router-dom";
import "./actionscontainer.scss";
const ActionsContainer = ({ row = null, data, openMessageModal = null }) => {
    const history = useHistory();
    const handleViewAllRecentNotifications = (v) => {
        history.push(notificationEventsRoute, {
            isAllNotification: true,
            isAllNotificationId: data === null || data === void 0 ? void 0 : data.itemId,
            isRoadmapItem: false,
            selectedItemId: null,
        });
    };
    return (React.createElement("div", { className: `watchlistActionContainer row align-center justify-content-center ${!(data === null || data === void 0 ? void 0 : data.notificationCount) && "zeroNotification"}` }, data && (data === null || data === void 0 ? void 0 : data.itemId) && (React.createElement("div", { className: "criticalMessages" },
        React.createElement("div", { className: "row align-items-center justify-content-center " },
            React.createElement(RenderSvg, { icon: "NotificationIcon", style: { color: "#1B998F" } }),
            React.createElement("p", { className: "bold-text" },
                (data === null || data === void 0 ? void 0 : data.notificationCount) !== 0 && (data === null || data === void 0 ? void 0 : data.notificationCount),
                " ",
                (data === null || data === void 0 ? void 0 : data.notificationCount) === 1 ? (React.createElement("span", { onClick: (e) => {
                        e.stopPropagation(); // Stop event propagation here
                        openMessageModal(data);
                    } }, "Notification")) : !(data === null || data === void 0 ? void 0 : data.notificationCount) ? (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "no-notification" }, "No Critical Notifications"),
                    React.createElement("p", { className: "view-more", onClick: (e) => {
                            e.stopPropagation();
                            handleViewAllRecentNotifications(); // Inner click handler
                        } }, localize(["Notification.viewAllNotifications"])))) : (React.createElement("span", { onClick: (e) => {
                        // e.preventDefault();
                        e.stopPropagation();
                        openMessageModal(data);
                    } }, "Notifications")))),
        (data === null || data === void 0 ? void 0 : data.actionRequired) && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "bold-text", onClick: (e) => {
                    e.stopPropagation();
                    openMessageModal(data);
                } }, "Urgent & Critical")))))));
};
export default ActionsContainer;
