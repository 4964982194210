import React from "react";
import "./style.scss";
import { Tooltip } from "antd";
import { formatDate } from "../../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { formatValueByConf } from "../../../../../../core/helpers/chartYAxisLabel";
import movingAveragePng from "../../../../../../assets/img/movingAverage.png";
import actualValuePng from "../../../../../../assets/img/actualValue.png";
import localize from "../../../../../../core/utils/translation-files";
const RatingColumnComponent = ({ record = null, column = "", bandData = null, setBandData = null, valueType, date, status, systemCreated = null, statusRgb, movingAverage, statusMA, decimals, projectionRequired = false, weight, weightFlag, actualValue = null, displayValue = null, showFlag = true, tooltipText = '' }) => {
    if (column === "predicted" && !projectionRequired) {
        return (React.createElement(Tooltip, { title: "No rating available due to Projection Required configurations", placement: "topRight" },
            React.createElement("span", { className: "bold-text NoDataCol" }, "No Rating")));
    }
    if (isEmpty(status)) {
        return (React.createElement("div", null,
            React.createElement(Tooltip, { title: column === "predicted"
                    ? localize(["TrackedItem.cannotIdentifyTrend"])
                    : localize(["TrackedItem.ratingIsStable"]), placement: "top" },
                React.createElement("div", { className: "bold-text NoDataCol" }, column === "predicted"
                    ? localize(["TrackedItem.noPrediction"])
                    : localize(["TrackedItem.stableRating"])))));
    }
    return (React.createElement("div", { style: { display: "flex" } },
        React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: tooltipText },
                React.createElement("div", { className: "rating-container" },
                    React.createElement("div", null, date
                        ? `${formatDate(date)
                            .split("-")
                            .join("/")
                            .slice(0, 6)}${formatDate(date)
                            .split("-")
                            .join("/")
                            .slice(-2)}`
                        : "N/A"),
                    React.createElement(Tooltip, { title: weight !== 0
                            ? // && !weightFlag
                                ""
                            : "This item doesn't contribute to the overall rating" },
                        React.createElement("div", null, status && weight !== 0
                            ? // && !weightFlag
                                status === null || status === void 0 ? void 0 : status.slice(-1)
                            : "-")),
                    React.createElement("div", { style: {
                            backgroundColor: `${!isEmpty(movingAverage) && statusRgb ? statusRgb : "#f9f9f9"}`,
                            color: `${!isEmpty(movingAverage) ? "white" : "black"}`,
                        } }, !isEmpty(movingAverage)
                        ? weight !== 0
                            ? // && !weightFlag
                                `${Math.trunc(+movingAverage)}%`
                            : displayValue === 0 &&
                                displayValue != null &&
                                actualValue != null
                                ? `${formatValueByConf(actualValue, valueType, decimals !== null && decimals !== void 0 ? decimals : 2)}`
                                : `${formatValueByConf(statusMA, valueType, decimals !== null && decimals !== void 0 ? decimals : 2)}`
                        : "-")))),
        React.createElement(Tooltip, { title: "Actual Value" }, !(weight !== 0 && !weightFlag) &&
            displayValue != null &&
            displayValue === 0 &&
            systemCreated !== null &&
            systemCreated === false &&
            actualValue != null && (React.createElement("div", null,
            " ",
            React.createElement("img", { src: actualValuePng, alt: "movingAveragePng", style: {
                    width: "22px",
                    height: "22px",
                    marginTop: "8px",
                    marginRight: "15px",
                } })))),
        React.createElement(Tooltip, { title: "Moving Average" }, !(weight !== 0 && !weightFlag) &&
            displayValue != null &&
            systemCreated !== null &&
            systemCreated === false &&
            displayValue === 1 && (React.createElement("div", null,
            " ",
            React.createElement("img", { src: movingAveragePng, alt: "actualValuePng", style: {
                    width: "22px",
                    height: "22px",
                    marginTop: "8px",
                    marginRight: "15px",
                } })))),
        ((weight !== 0 &&
            // && !weightFlag
            column !== "predicted") ||
            systemCreated === true) &&
            showFlag && (React.createElement("div", null,
            " ",
            React.createElement("img", { src: null, alt: "", style: {
                    width: "22px",
                    height: "22px",
                    marginTop: "8px",
                    marginRight: "15px",
                    opacity: 0,
                } })))));
};
export default RatingColumnComponent;
