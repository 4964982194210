import React, { useCallback, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import classNames from "../../../core/utils/class-names";
import MenuGroupTitle from "./GroupTitle";
import ItemWithSub from "./ItemWithSub";
import SimpleItem from "./SimpleItem";
import "./Menu.scss";
import usePermissions from "../../../core/hooks/usePermissions";
import { treeMenuType } from "../../../core/enums/treeMenuType";
import { isMenuAllowed } from "../../../core/utils/allowedMenus";
import isEmpty from "../../../core/helpers/isEmpty";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { SPRINT_VERSION } from "../../../core/constants/termsCondition";
import Logo from "../logo/logo";
import LogoPng from "../../../assets/img/whiteLogo.png";
const haveActive = (sub, route) => {
    return Array.isArray(sub)
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
            sub.some((item) => item.routing === route || haveActive(item.sub, route))
        : false;
};
const Menu = ({ data, orientation, location, children, className, onCloseSidebar, opened, isAdmin, toggleSidebar, resetSideBar, }) => {
    const history = useHistory();
    const [menu, setMenu] = useState([]);
    const [renderKey, setRenderKey] = useState(0);
    const { isSingleGroup, isSingleCompany, groupOptions } = usePermissions(true);
    const mapMenuItems = useCallback((data, currentRoute) => {
        return data.map((item) => {
            if (item.sub) {
                let updateChildStatus = treeMenuType.expandable;
                if ((item.routing === "groups" && !isSingleGroup) ||
                    (item.routing === "companies" && !isSingleCompany)) {
                    updateChildStatus = treeMenuType.collapse;
                }
                return Object.assign(Object.assign({}, item), { active: item.routing === currentRoute, status: haveActive(item.sub, currentRoute)
                        ? treeMenuType.expanded
                        : updateChildStatus, sub: mapMenuItems(item.sub, currentRoute) });
            }
            return Object.assign(Object.assign({}, item), { active: item.routing === currentRoute });
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, groupOptions]);
    const handleUpdateMenuStatus = (menu, menuTitle, status) => {
        return menu.map((item) => {
            if (item.sub) {
                let updateChildStatus = item.status;
                //parent close to expandable, child collapse
                if (menuTitle === "groups" && status === treeMenuType.expandable) {
                    updateChildStatus =
                        item.routing === "companies" && isSingleCompany
                            ? treeMenuType.expandable
                            : treeMenuType.collapse;
                }
                return Object.assign(Object.assign({}, item), { active: item.routing === menuTitle, status: menuTitle === item.routing ? status : updateChildStatus, sub: handleUpdateMenuStatus(item.sub, menuTitle, status) });
            }
            return Object.assign(Object.assign({}, item), { status: menuTitle === item.routing ? status : item.status });
        });
    };
    useEffect(() => {
        var _a, _b;
        const currentRoute = location.pathname.split("/")[2];
        const updatedMenu = (_b = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.updatedMenu) !== null && _b !== void 0 ? _b : (data ? mapMenuItems(data, currentRoute) : []);
        setMenu((prev) => updatedMenu);
    }, [location, data, mapMenuItems]);
    useEffect(() => {
        onCloseSidebar && opened && onCloseSidebar();
    }, [location, onCloseSidebar, opened]);
    const handleItemClick = (itemTitle, layout, parentRoute, status) => {
        const updateMenu = [...menu];
        resetSideBar();
        let updateStatus;
        if ((parentRoute === "groups" && isSingleGroup) ||
            (parentRoute === "companies" && isSingleCompany)) {
            updateStatus =
                status === treeMenuType.expandable
                    ? treeMenuType.expanded
                    : treeMenuType.expandable;
        }
        const updatedMenu = handleUpdateMenuStatus(updateMenu, parentRoute, updateStatus);
        setMenu(updatedMenu);
        setRenderKey(Math.random());
        if (!isEmpty(parentRoute)) {
            history.push(`/${layout}/${parentRoute}`, { updatedMenu });
        }
    };
    const menuClasses = classNames({
        "main-menu": true,
        horizontal: orientation === "horizontal",
    });
    const menuItems = menu.map((item, i) => {
        if (item.groupTitle && isMenuAllowed(item.requiredPermissions) === true) {
            if (!opened) {
                return null;
            }
            return (React.createElement(MenuGroupTitle, { key: i, title: item.title, layout: orientation, routing: item.routing }));
        }
        if (item.sub) {
            return (React.createElement(ItemWithSub, { key: i, layout: orientation, sub: item.sub, title: item.title, location: location, active: item.active, icon: item.icon, onClick: handleItemClick, status: item === null || item === void 0 ? void 0 : item.status, parentRoute: item === null || item === void 0 ? void 0 : item.routing, requiredPermissions: item === null || item === void 0 ? void 0 : item.requiredPermissions, isParent: true, expanded: opened, svgIcon: item === null || item === void 0 ? void 0 : item.svgIcon }));
        }
        return (isMenuAllowed(item.requiredPermissions) === true && (React.createElement(SimpleItem, { key: i, icon: item.icon, layout: orientation, title: item.title, routing: item.routing, isAdmin: isAdmin, adminTitle: item.adminTitle, child: item.child, show: item.show, expanded: opened, resetSideBar: resetSideBar, svgIcon: item.svgIcon })));
    });
    // Assuming menuItems is an array containing your menu items
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${menuClasses} ${!!className && className}`, key: renderKey },
            children,
            React.createElement("span", { className: "navbar-toggle", onClick: toggleSidebar, style: { alignItems: opened ? `flex-end` : `center` } }, opened ? (React.createElement(React.Fragment, null,
                React.createElement(Logo, { src: LogoPng, className: "menuDelphiLogo" }),
                React.createElement(MenuFoldOutlined, { className: "menuFoldOutlineIcon" }))) : (React.createElement(MenuUnfoldOutlined, null))),
            !!menuItems.length && (React.createElement("nav", { className: "main-menu-wrap", id: "main-menu-wrap" },
                React.createElement("ul", { className: "menu-ul" }, menuItems)))),
        React.createElement("div", { className: "navbar-version-text" },
            "Version ",
            SPRINT_VERSION)));
};
export default withRouter(Menu);
