import moment from "moment";
import React from "react";
import { defaultFullDateTimeFormatForNotification } from "../../../../../core/constants/DateTime";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import "../style.scss";
import { decryptData } from "../../../../../core/utils/encryptionUtils";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
const NotificationModel = ({ visible, onClose, selectedNotification, flagNotification, unflagNotification, }) => {
    var _a, _b, _c, _d;
    (_a = document.getElementById("flagLink")) === null || _a === void 0 ? void 0 : _a.setAttribute("target", "_self");
    return (React.createElement("div", { "data-test": "notification-model" },
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: [""], subTitle: decryptData((_b = selectedNotification.subject) !== null && _b !== void 0 ? _b : ""), 
            // width="min-content"
            modelClass: "inAppPopup" },
            React.createElement("div", { className: "col-12", style: { display: "flex", justifyContent: "space-between" } },
                React.createElement("span", { style: { fontWeight: "bold" } }, moment
                    .utc((_c = selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.createdDate) === null || _c === void 0 ? void 0 : _c.toString())
                    .format(defaultFullDateTimeFormatForNotification)),
                React.createElement("div", { className: "model-flag" },
                    React.createElement(RenderSvg, { tooltipTitle: localize([
                            (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged)
                                ? "Notification.flag"
                                : "Notification.unflag",
                        ]), icon: `${(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged)
                            ? "RatingFlagFilled"
                            : "RatingFlag"}`, style: {
                            fontSize: "1.7em",
                            color: "#6e6b7b",
                        }, onClick: (event) => {
                            event.stopPropagation();
                            (selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.flagged) === true
                                ? unflagNotification(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id)
                                : flagNotification(selectedNotification === null || selectedNotification === void 0 ? void 0 : selectedNotification.id);
                        } }))),
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "notificationTemplate", dangerouslySetInnerHTML: {
                        __html: decryptData((_d = selectedNotification.body) !== null && _d !== void 0 ? _d : ""),
                    } })))));
};
export default NotificationModel;
