import React from "react";
import Icon from "@ant-design/icons";
import { smallHistoricalScale, largeHistoricalScale, largeGraphIcon, BtoC, CtoD, CtoC, DtoD, BtoB, AtoA, BtoA, DtoC, CtoB, AtoB, } from "./PredictionChartSvg";
import { AddCompanySvg, AddGroupSvg, AddRoleSvg, AddSiteSvg, AddUserSvg, AddUserTrackItemSvg, EditSvg, ModifySvg, EmailSvg, FilterDropdownSvg, InviteUserSvg, LockUserSvg, PrintSvg, RatingGraphSvg, TrackItemChartSvg, FinancialDataSvg, UnlockUserSvg, ViewSvg, ExpandTableSvg, CollapseTableSvg, InActiveNotificationBellSvg, ActiveNotificationBellSvg, readNotificationSvg, unreadNotificationSvg, ResetSvg, ResetDisabledSvg, AddDefaultSvg, ExportSvg, DownArrowSvg, UpArrowSvg, GotoSvg, TableFormatSvg, AllRelatedItemsSvg, IndividualItemSvg, CancelSvg, MessageSvg, RoadMapFlagSvg, WatchlistButtonSvg, FinancialSheetSvg, InfoSvg, RoadmapCollapseAllSvg, RoadmapExpandAllSvg, MoreActionsSvg, AddedToWatchlistSvg, WatchlistMessageSvg, CheckSvg, openChartSvg, loaderSvg, BackArrowSvg, HomeSvg, HomeAltSvg, NotificationIconSvg, DeleteSvg, RefreshSvg, RatingFlagSvg, AlertSvg, LocationIconSvg, ExclamationMarkSvg, NotificationSettingSvg, DataQualityFlagSvg, DefaultWatchListSvg, NonHoldingGroupSvg, HoldingGroupSvg, RatingFlagFilledSvg, } from "./iconsSvg";
import { DashboardMenuSvg, WatchlistMenuSvg, InActiveRoadmapMenuSvg, ActiveRoadmapMenuSvg, ActiveGroupMenuSvg, InActiveGroupMenuSvg, CompanyMenuSvg, SiteMenuSvg, UserMenuSvg, ActiveRoleMenuSvg, InActiveRoleMenuSvg, ActiveTrackItemMenuSvg, InActiveTrackItemMenuSvg, ActiveDefaultMenuSvg, InActiveDefaultMenuSvg, ActiveNotificationsMenuSvg, InActiveNotificationsMenuSvg, ActiveFinancialReportsMenuSvg, InActiveFinancialReportsMenuSvg, InActiveLookupMenuSvg, ActiveLookupMenuSvg, ActiveManageNotificationsMenuSvg, InActiveManageNotificationsMenuSvg, } from "./menuIconsSvg";
import localize from "../../../core/utils/translation-files";
import isEmpty from "../../../core/helpers/isEmpty";
const iconComponent = {
    Edit: EditSvg,
    Modify: ModifySvg,
    View: ViewSvg,
    LockUser: LockUserSvg,
    UnLockUser: UnlockUserSvg,
    AddUser: AddUserSvg,
    Email: EmailSvg,
    TrackItemChart: TrackItemChartSvg,
    FinancialData: FinancialDataSvg,
    AddUserTrackItem: AddUserTrackItemSvg,
    Print: PrintSvg,
    RatingGraph: RatingGraphSvg,
    InviteUser: InviteUserSvg,
    AddGroup: AddGroupSvg,
    AddCompany: AddCompanySvg,
    AddSite: AddSiteSvg,
    AddRole: AddRoleSvg,
    FilterDropdown: FilterDropdownSvg,
    ActiveNotificationBell: ActiveNotificationBellSvg,
    InActiveNotificationBell: InActiveNotificationBellSvg,
    readNotification: readNotificationSvg,
    unreadNotification: unreadNotificationSvg,
    Reset: ResetSvg,
    ResetDisabled: ResetDisabledSvg,
    AddDefault: AddDefaultSvg,
    Export: ExportSvg,
    DownArrow: DownArrowSvg,
    UpArrow: UpArrowSvg,
    Goto: GotoSvg,
    TableFormat: TableFormatSvg,
    AllRelatedItems: AllRelatedItemsSvg,
    IndividualItem: IndividualItemSvg,
    Cancel: CancelSvg,
    Message: MessageSvg,
    RoadMapFlag: RoadMapFlagSvg,
    ExclamationMarkFlag: ExclamationMarkSvg,
    WatchlistButton: WatchlistButtonSvg,
    CheckMark: CheckSvg,
    FinancialSheet: FinancialSheetSvg,
    Delete: DeleteSvg,
    Info: InfoSvg,
    RoadmapCollapseAll: RoadmapCollapseAllSvg,
    RoadmapExpandAll: RoadmapExpandAllSvg,
    MoreActions: MoreActionsSvg,
    OpenChart: openChartSvg,
    Loader: loaderSvg,
    BackArrow: BackArrowSvg,
    Refresh: RefreshSvg,
    RatingFlag: RatingFlagSvg,
    RatingFlagFilled: RatingFlagFilledSvg,
    Alert: AlertSvg,
    Home: HomeSvg,
    HomeAlt: HomeAltSvg,
    AddedToWatchlist: AddedToWatchlistSvg,
    NotificationIcon: NotificationIconSvg,
    WatchlistMessage: WatchlistMessageSvg,
    smallHistoricalScale: smallHistoricalScale,
    largeHistoricalScale: largeHistoricalScale,
    Location: LocationIconSvg,
    viewLargeGraph: largeGraphIcon,
    AtoB: AtoB,
    BtoC: BtoC,
    CtoD: CtoD,
    DtoC: DtoC,
    CtoB: CtoB,
    BtoA: BtoA,
    AtoA: AtoA,
    BtoB: BtoB,
    CtoC: CtoC,
    DtoD: DtoD,
    ///////menu items
    DashboardMenu: DashboardMenuSvg,
    WatchlistMenu: WatchlistMenuSvg,
    InActiveRoadmapMenu: InActiveRoadmapMenuSvg,
    ActiveRoadmapMenu: ActiveRoadmapMenuSvg,
    ActiveGroupMenu: ActiveGroupMenuSvg,
    InActiveGroupMenu: InActiveGroupMenuSvg,
    CompanyMenu: CompanyMenuSvg,
    SiteMenu: SiteMenuSvg,
    UserMenu: UserMenuSvg,
    ActiveRoleMenu: ActiveRoleMenuSvg,
    InActiveRoleMenu: InActiveRoleMenuSvg,
    ActiveLookupMenu: ActiveLookupMenuSvg,
    InActiveLookupMenu: InActiveLookupMenuSvg,
    ActiveTrackItemMenu: ActiveTrackItemMenuSvg,
    InActiveTrackItemMenu: InActiveTrackItemMenuSvg,
    ActiveDefaultMenu: ActiveDefaultMenuSvg,
    InActiveDefaultMenu: InActiveDefaultMenuSvg,
    ActiveNotificationsMenu: ActiveNotificationsMenuSvg,
    InActiveNotificationsMenu: InActiveNotificationsMenuSvg,
    ActiveManageNotificationsMenu: ActiveManageNotificationsMenuSvg,
    InActiveManageNotificationMenu: InActiveManageNotificationsMenuSvg,
    InActiveFinancialReportsMenu: InActiveFinancialReportsMenuSvg,
    ActiveFinancialReportsMenu: ActiveFinancialReportsMenuSvg,
    ExpandTable: ExpandTableSvg,
    CollapseTable: CollapseTableSvg,
    NotificationSettingSvg: NotificationSettingSvg,
    DataQualityFlag: DataQualityFlagSvg,
    DefaultWatchList: DefaultWatchListSvg,
    NonHoldingGroup: NonHoldingGroupSvg,
    HoldingGroup: HoldingGroupSvg,
};
const renderIconComponent = (tooltipTitle, icon) => {
    const SvgComponent = iconComponent[icon];
    if (SvgComponent) {
        if (isEmpty(tooltipTitle))
            return SvgComponent();
        return SvgComponent(Array.isArray(tooltipTitle) ? localize(tooltipTitle) : tooltipTitle);
    }
    return React.createElement(React.Fragment, null);
};
const RenderSvg = ({ icon, onClick, tooltipTitle, style, disabled = false, hidden = false, className = "", }) => (React.createElement(Icon, { className: className, component: () => renderIconComponent(tooltipTitle, icon), onClick: disabled ? null : onClick, style: style, hidden: hidden && hidden }));
export default RenderSvg;
