var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loginRoute, resetPasswordRoute } from "./../../constants/routesPath";
import { SessionStorage } from "./../../helpers/localStorage/sessionStorage";
import { ApiError } from "./apiErrors";
import { apiConstants } from "./constants";
import queryString from "query-string";
import { API_DOMAIN_PATH } from "../../../config/constants";
import { handleApiMessages, notifications, } from "../../../layout/components/notifications";
import { trackPromise } from "react-promise-tracker";
import { SessionVariables } from "../../constants/SessionVariables";
import { AccountApi } from "../accountApi";
import isEmpty from "../../helpers/isEmpty";
export const httpGet = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    //need improvement
    if (options === null || options === void 0 ? void 0 : options.area) {
        return yield trackPromise(requestWithRefreshToken(url, "GET", options), options.area);
    }
    if (url.includes("getUnreadCount") ||
        url.includes("getLastNNotifications") ||
        url.includes("cqc") ||
        (options === null || options === void 0 ? void 0 : options.silent)) {
        return yield requestWithRefreshToken(url, "GET", options);
    }
    return yield trackPromise(requestWithRefreshToken(url, "GET", options));
});
export const httpPost = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    if (options === null || options === void 0 ? void 0 : options.area) {
        return yield trackPromise(requestWithRefreshToken(url, "POST", options), options.area);
    }
    if (options === null || options === void 0 ? void 0 : options.silent) {
        return yield requestWithRefreshToken(url, "POST", options);
    }
    return yield trackPromise(requestWithRefreshToken(url, "POST", options));
});
export const httpPut = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield trackPromise(requestWithRefreshToken(url, "PUT", options));
});
export const httpDelete = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    yield trackPromise(requestWithRefreshToken(url, "DELETE", options));
});
export const getAsFormData = (params) => {
    const formData = new FormData();
    if (params.file) {
        formData.append("file", params.file);
    }
    formData.append("requestData", JSON.stringify(params.requestData));
    return formData;
};
export const requestWithRefreshToken = (url, method, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(url, method, Object.assign(Object.assign({}, options), { requestHeader: true }));
    const data = yield mapResponseToData(response);
    handleApiMessages(response, data);
    if (response.ok ||
        ((data === null || data === void 0 ? void 0 : data.code) &&
            (data === null || data === void 0 ? void 0 : data.code) !== "JWT_TOKEN_EXPIRED" &&
            (data === null || data === void 0 ? void 0 : data.code) !== "UnAuthorized")) {
        const refreshToken = response.headers.get("REFRESH_TOKEN");
        if (refreshToken) {
            SessionStorage.setKey(SessionVariables.REFRESH_TOKEN, refreshToken);
        }
        return data;
    }
    else if (response.status === 401 &&
        window.location.pathname !== loginRoute &&
        window.location.pathname !== resetPasswordRoute &&
        !(response === null || response === void 0 ? void 0 : response.url.includes("logout")) &&
        !(response === null || response === void 0 ? void 0 : response.url.includes("refreshToken"))) {
        const refreshResponseData = yield request("auth/refreshToken", "GET");
        if (refreshResponseData === null || refreshResponseData === void 0 ? void 0 : refreshResponseData.jwt) {
            SessionStorage.setKey(SessionVariables.AUTH_TOKEN, refreshResponseData.jwt);
            const myResponse = yield request(url, method, Object.assign(Object.assign({}, options), { refetchedJWT: refreshResponseData === null || refreshResponseData === void 0 ? void 0 : refreshResponseData.jwt }));
            return myResponse;
        }
        else if (refreshResponseData === null || refreshResponseData === void 0 ? void 0 : refreshResponseData.code) {
            SessionStorage.clearStorage();
            window.location.href = loginRoute;
        }
    }
    else if (response.status === 401 &&
        window.location.pathname !== loginRoute) {
        SessionStorage.clearStorage();
        window.location.href = loginRoute;
        return;
    }
    else if (response.status >= 500) {
        notifications.error(`Oops! Something went wrong! ${!isEmpty(response.status) ? `(${response.status})` : ""}`);
        return;
    }
    else {
        processFailedResponse(response);
    }
    return response;
});
const request = (url, method, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    let response;
    try {
        toggleLoading(true, options);
        const params = options && options.params
            ? `?${queryString.stringify(options.params)}`
            : "";
        let tokenHeader = {};
        const isRefreshToken = url.includes("refreshToken");
        const tokenString = isRefreshToken
            ? SessionStorage.getKey(SessionVariables.REFRESH_TOKEN)
            : (_a = options === null || options === void 0 ? void 0 : options.refetchedJWT) !== null && _a !== void 0 ? _a : SessionStorage.getKey(SessionVariables.AUTH_TOKEN);
        if (tokenString) {
            tokenHeader = {
                [isRefreshToken ? "REFRESH_TOKEN" : "Authorization"]: "Bearer " + tokenString,
            };
        }
        response = yield fetch(API_DOMAIN_PATH + url + params, Object.assign(Object.assign({ body: getBody(options) }, ((options === null || options === void 0 ? void 0 : options.abortSignal) && { signal: options.abortSignal })), { headers: Object.assign(Object.assign({ Accept: "application/json", "Access-Control-Allow-Origin": "*" }, tokenHeader), getContentTypeHeader(options)), method }));
    }
    catch (error) {
        throw error;
    }
    finally {
        toggleLoading(false, options);
    }
    if (options === null || options === void 0 ? void 0 : options.requestHeader) {
        return response;
    }
    const responseValue = yield handleResponse(response);
    return responseValue;
});
const getBody = (options) => {
    if (!options) {
        return undefined;
    }
    if (options.body === null ||
        typeof options.body !== "object" ||
        options.body instanceof FormData) {
        return options.body;
    }
    return JSON.stringify(options.body);
};
const getContentTypeHeader = (options) => {
    if (options === null || options === undefined) {
        return { "Content-Type": "text/plain" };
    }
    if (options.body instanceof FormData) {
        return {};
    }
    return {
        "Content-Type": typeof options.body === "object" ? "application/json" : "text/plain",
    };
};
const mapResponseToData = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const contentType = response.headers.get("Content-Type");
    if (contentType === "application/json") {
        const text = yield response.text();
        return text ? JSON.parse(text) : {};
    }
    else if (contentType === "application/octet-stream") {
        return yield response.blob();
    }
    else {
        return yield response;
    }
});
// there are too many problems with passing non-object values - let's stick only with objects
const handleResponse = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const data = yield mapResponseToData(response);
    handleApiMessages(response, data);
    if (response.ok || ((data === null || data === void 0 ? void 0 : data.code) && data.code !== "JWT_TOKEN_EXPIRED")) {
        return data;
    }
    else if (response.status === 401 &&
        window.location.pathname !== loginRoute) {
        console.log("logout");
        SessionStorage.clearStorage();
        window.location.href = loginRoute;
        return;
    }
    else if (response.status >= 500) {
        notifications.error(`Oops! Something went wrong! ${!isEmpty(response.status) ? `(${response.status})` : ""}`);
        return;
    }
    else {
        processFailedResponse(response);
    }
});
const refresh = (callback) => __awaiter(void 0, void 0, void 0, function* () {
    AccountApi.refreshToken().then((res) => {
        if (res.jwt !== "" && res.code !== "REFRESH_TOKEN_INVALID") {
            SessionStorage.setKey(SessionVariables.AUTH_TOKEN, res.jwt);
            callback();
            window.location.reload();
            return;
        }
        else {
            SessionStorage.clearStorage();
            window.location.href = loginRoute;
            return;
        }
    });
});
const processFailedResponse = (response) => {
    const errorCode = response.headers.get(apiConstants.headers.errorCode);
    const errorId = response.headers.get(apiConstants.headers.errorId);
    const payloadError = response.headers.get(apiConstants.headers.payloadError);
    throw new ApiError(response, errorCode, errorId, payloadError ? JSON.parse(payloadError) : {});
};
function toggleLoading(show, options) {
    if (!options || !options.silent) {
        if (show) {
        }
        else {
        }
    }
}
