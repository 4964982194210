import { Button, DatePicker, Tabs } from "antd";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { DASHBOARD_VIEWER, REPORT_CATEGORY_CARE_FINANCIALS, USER_REPORTS_FINANCIAL, } from "../../../../core/constants/permissionConstants";
import React, { useEffect, useRef, useState } from "react";
import localize from "../../../../core/utils/translation-files";
import { SessionStorage } from "../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../core/constants/SessionVariables";
import UsePrintPage from "../../../../core/hooks/usePrintPage";
import isEmpty from "../../../../core/helpers/isEmpty";
import usePermissions from "../../../../core/hooks/usePermissions";
import UsePersistCustomFilters from "../../../../core/hooks/usePersistCustomFilters";
import ReportsFiltersModel from "./components/ReportsFilterModel";
import FinancialReportsTable from "./components/FinancialReportsTable";
import { ReportsApi } from "../../../../core/api/reportsApi";
import "./reports.scss";
import RenderSvg from "../../../../layout/components/icons/RenderSvg";
import PrintButton from "../../../../layout/components/common/PrintButton";
import SelectedListingFilters from "../../../../layout/components/selectedListingFilters";
import Header from "../../../../layout/components/common/header";
import TableActions from "../../../../layout/components/customTable/tableActions";
import { useHistory } from "react-router-dom";
import UnAuthorized from "../../../global/sessions/401";
import { connect, useSelector } from "react-redux";
import { LookupNames } from "../../../../core/constants/lookup";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import { disabledDate } from "../../../../core/validations/dateTimeValidations";
import moment from "moment";
import { ReportFilterOptions } from "../../../../core/enums/report";
import useDidMount from "../../../../core/hooks/useDidMount";
import { getLookupByListing } from "../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../redux/lookup/actions";
import useDashboardHierarchy from "../../../../core/hooks/useDashboardHeirarchy";
import { viewTrackedItemChart } from "../../../../core/helpers/dasboardRoute";
import { TrackedItemApi } from "../../../../core/api/trackedItemApi";
import { exportFinancialDataSheet } from "../../../../core/helpers/chartYAxisLabel";
import { getFullDate } from "../../../../core/helpers/dateFormatter";
import DataSheetsModal from "./components/DataSheetsModal";
import $ from "jquery";
import html2canvas from "html2canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfContent } from "../../../../layout/components/customPdfContent";
import DownloadDataLoader from "../../../../layout/components/customPdfContent/DownloadDataLoader";
import { clearDashboardSession } from "../../../../core/helpers/localStorage/breadCrumbSession";
const ReportsContainer = ({ getLookups }) => {
    var _a, _b, _c, _d, _e;
    const history = useHistory();
    const isFirstRender = useRef(true);
    const u = (_b = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "";
    const url = !isEmpty(u) && new URL(u);
    u &&
        isFirstRender.current &&
        SessionStorage.setKey(SessionVariables.FINANCIAL_MODULE, {
            filterValues: {
                groupId: +(url === null || url === void 0 ? void 0 : url.searchParams.get("group")),
                companyId: +(url === null || url === void 0 ? void 0 : url.searchParams.get("company")),
                siteId: +(url === null || url === void 0 ? void 0 : url.searchParams.get("site")),
            },
            tab: url === null || url === void 0 ? void 0 : url.searchParams.get("tab"),
            type: url === null || url === void 0 ? void 0 : url.searchParams.get("reportType"),
            parentKey: url === null || url === void 0 ? void 0 : url.searchParams.get("parentKey"),
        });
    const isReportViewAllowed = isActionAllowed([USER_REPORTS_FINANCIAL]);
    const isDashboardViewAllowed = isActionAllowed([DASHBOARD_VIEWER]);
    const isFinancialViewAllowed = isActionAllowed([
        REPORT_CATEGORY_CARE_FINANCIALS,
    ]);
    const { TabPane } = Tabs;
    const { handlePrint, nodeRef } = UsePrintPage();
    const { filterValues, tab, type, parentKey } = (_c = SessionStorage.getKey(SessionVariables.FINANCIAL_MODULE)) !== null && _c !== void 0 ? _c : {};
    const [selectedTab, setSelectedTab] = useState(tab !== null && tab !== void 0 ? tab : (SessionStorage.getKey(SessionVariables.SELECTED_REPORTS_LISTING_TAB) ||
        "1"));
    const { isSingleSite } = usePermissions();
    const [filterModalVisibility, setFilterModalVisibility] = useState(false);
    const [dataSheetModalVisibility, setDataSheetModalVisibility] = useState(false);
    const [INITIAL_TAB_OPTIONS] = useState([
        { titleKey: "balanceSheet", reportId: 3, listing: null },
        { titleKey: "profitNLoss", reportId: 2, listing: null },
        { titleKey: "cashFlow", reportId: 4, listing: null },
        {
            titleKey: "summarySheetRatio",
            reportId: 1,
            listing: null,
        },
    ]);
    const [tabOptions, setTabOptions] = useState(INITIAL_TAB_OPTIONS);
    const openFilterModal = () => {
        setFilterModalVisibility(true);
    };
    const closeFilterModal = () => {
        setFilterModalVisibility(false);
    };
    const [excelData, setExcelData] = useState();
    const [formula, setFormula] = useState();
    const completedResult = (dataObject, formula) => {
        setExcelData(dataObject);
        formula &&
            setFormula(formula === null || formula === void 0 ? void 0 : formula.substring((formula === null || formula === void 0 ? void 0 : formula.indexOf("=")) + 1, formula === null || formula === void 0 ? void 0 : formula.length));
    };
    const [fiscalRange, setFiscalRange] = useState({});
    const openDataSheetModal = (type, name) => {
        setExcelData(undefined);
        setDataSheetModalVisibility(true);
        let fiscalYearRange = {};
        selectedFilterValue === 1 &&
            ReportsApi.getFiscalYearRange(groupCompanySiteId).then((res) => {
                fiscalYearRange = res;
                setFiscalRange(res);
            });
        TrackedItemApi.getTrackedItemId(type, groupCompanySiteId).then((id) => {
            setInitialPayload(Object.assign(Object.assign({}, initialPayload), { reportId: id, reportName: name }));
            exportFinancialDataSheet(selectedFilterValue === ReportFilterOptions.Default ? 0 : 2, id, initialPayload.startDate
                ? [
                    getFullDate(initialPayload.startDate),
                    getFullDate(initialPayload.endDate),
                ]
                : selectedFilterValue === ReportFilterOptions.Fiscal
                    ? [fiscalYearRange.startDate, fiscalYearRange.endDate]
                    : [], null, completedResult, true);
        });
    };
    const closeDataSheetModal = () => {
        setDataSheetModalVisibility(false);
    };
    const { setFilters, filters } = UsePersistCustomFilters({
        defaultValue: filterValues !== null && filterValues !== void 0 ? filterValues : {},
    });
    // const clearFinancialSession = () => {
    //   if (filters) {
    //     // SessionStorage.removeKey(SessionVariables.FINANCIAL_MODULE);
    //   }
    // };
    useEffect(() => {
        if (filters) {
            window.addEventListener("beforeunload", (event) => {
                window.history.replaceState({}, document.title);
                SessionStorage.removeKey(SessionVariables.FINANCIAL_MODULE);
            });
            // window.scrollTo(0, 0);
        }
    });
    const hide = useSelector((state) => state.togglePrint);
    useEffect(() => {
        return () => {
            SessionStorage.removeKey(SessionVariables.FINANCIAL_MODULE);
        };
    }, []);
    // useEffect(() => {
    //   if (filters && !isFirstRender.current) {
    //     // clearFinancialSession();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTab, filters]);
    const handleChangeTab = (activeKey) => {
        // if (activeKey !== selectedTab) {
        SessionStorage.setKey(SessionVariables.SELECTED_REPORTS_LISTING_TAB, activeKey);
        setSelectedTab(activeKey);
        // }
    };
    //  Step-1 getting groupId, companyId Also
    const { groupId, companyId, siteId } = filters;
    const [initialPayload, setInitialPayload] = useState({
        reportId: undefined,
        reportName: 0,
        startDate: null,
        endDate: null,
        companyId: null,
        reportFilterOption: ReportFilterOptions.Default,
    });
    useEffect(() => {
        if (
        // tabOptions[3]?.listing && selectedTab === "4" &&
        type) {
            const targetElement = document.getElementById("selectedRowId");
            targetElement === null || targetElement === void 0 ? void 0 : targetElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    }, [selectedTab, type, tabOptions]);
    useEffect(() => {
        var _a;
        if (!isEmpty(filters) && isFirstRender.current) {
            isFirstRender.current = false;
            tab &&
                SessionStorage.setKey(SessionVariables.SELECTED_REPORTS_LISTING_TAB, tab);
            filterReportHandler((_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.siteId) !== null && _a !== void 0 ? _a : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, isFirstRender]);
    useEffect(() => {
        filterReportHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);
    const handleApplyFilter = (selectedSite) => {
        setTabOptions(INITIAL_TAB_OPTIONS);
        filterReportHandler(selectedSite, true);
    };
    const { RangePicker } = DatePicker;
    const [selectedFilterValue, setSelectedFilterValue] = useState(ReportFilterOptions.Default);
    const [selectedRange, setSelectedRange] = useState([]);
    const onFilterChange = (value) => {
        setSelectedRange([]);
        setSelectedFilterValue(value);
        if (value !== ReportFilterOptions.Custom) {
            setInitialPayload(Object.assign(Object.assign({}, initialPayload), { reportFilterOption: value, startDate: null, endDate: null }));
        }
    };
    const onRangeChange = (date, dateString) => {
        setSelectedRange(dateString);
        setInitialPayload(Object.assign(Object.assign({}, initialPayload), { reportFilterOption: ReportFilterOptions.Custom, startDate: dateString[0], endDate: dateString[1] }));
    };
    useEffect(() => {
        var _a, _b;
        setTabOptions(INITIAL_TAB_OPTIONS);
        let companyId = fetchCompanyId((_b = (_a = filterValues === null || filterValues === void 0 ? void 0 : filterValues.siteId) !== null && _a !== void 0 ? _a : filterValues === null || filterValues === void 0 ? void 0 : filterValues.companyId) !== null && _b !== void 0 ? _b : filterValues === null || filterValues === void 0 ? void 0 : filterValues.groupId);
        filterReportHandler(companyId, true); // siteId changes to companyId
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        initialPayload.reportFilterOption,
        initialPayload.startDate,
        initialPayload.endDate,
    ]);
    const fetchCompanyId = (siteId) => {
        var _a, _b;
        return siteId == null || siteId === -1
            ? (_b = (_a = filters.siteId) !== null && _a !== void 0 ? _a : filters.companyId) !== null && _b !== void 0 ? _b : filters === null || filters === void 0 ? void 0 : filters.groupId
            : filterValues === null || filterValues === void 0 ? void 0 : filterValues.siteId;
    };
    const filterReportHandler = (selectedSite = null, isSubmitAction = false) => {
        var _a;
        const selectedSiteId = typeof selectedSite == "number" ? selectedSite : fetchCompanyId(-1);
        if (!isEmpty(selectedSiteId)) {
            const { reportId, listing } = (_a = tabOptions[selectedTab - 1]) !== null && _a !== void 0 ? _a : {};
            const payload = Object.assign(Object.assign({}, initialPayload), { companyId: selectedSiteId });
            if (isSubmitAction || isEmpty(listing)) {
                ReportsApi.getFinancialReport(Object.assign(Object.assign({}, payload), { reportId })).then((response) => {
                    setTabOptions((prev) => {
                        const updateTabsOption = JSON.parse(JSON.stringify(prev));
                        updateTabsOption[selectedTab - 1].listing = (response === null || response === void 0 ? void 0 : response.data)
                            ? response
                            : [];
                        return updateTabsOption;
                    });
                });
            }
            setFilterModalVisibility(false);
        }
    };
    const { isSinglSite, isSingleCompany, isSingleCompanyAndSite, CompanyByGroup, SiteByCompany, } = useDashboardHierarchy();
    const groupCompanySiteId = (_d = siteId !== null && siteId !== void 0 ? siteId : companyId) !== null && _d !== void 0 ? _d : groupId;
    const viewChart = (trackedItem) => {
        TrackedItemApi.getTrackedItemId(trackedItem === null || trackedItem === void 0 ? void 0 : trackedItem.type, groupCompanySiteId).then((res) => {
            viewTrackedItemChart(res, filters, groupCompanySiteId, history, CompanyByGroup, SiteByCompany, isSingleCompanyAndSite, isSinglSite, isSingleCompany, isDashboardViewAllowed, isFinancialViewAllowed, true);
        });
    };
    const summaryItemActions = (report, value) => {
        const actionArray = [
            {
                priority: 1,
                title: ["view", "financial", "data"],
                onClick: openDataSheetModal.bind({}, report === null || report === void 0 ? void 0 : report.type, value),
                svgIcon: "FinancialData",
                iconStyle: {
                    color: "grey",
                    background: "white",
                    padding: "5px",
                },
            },
            {
                priority: 2,
                title: ["view", "chart"],
                onClick: viewChart.bind({}, report),
                svgIcon: "TrackItemChart",
                iconStyle: { color: "grey", background: "white", padding: "5px" },
            },
        ];
        return (React.createElement(TableActions, { actionArray: actionArray, wrapperClass: "summary_actions_buttons" }));
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKReportDataFilters],
            getLookups,
        });
    });
    const [pdfContent, setPdfContent] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const loadAllData = () => {
        setLoadingProgress(0);
        setLoadingData(true);
        const interval = setInterval(() => {
            setLoadingProgress((loadingProgress) => loadingProgress >= 70 ? loadingProgress : loadingProgress + 5);
        }, 500);
        setTimeout(() => {
            var _a;
            html2canvas(document.querySelector(".reportsListingTabs"), {
                scale: ((_a = document.querySelector(".reportsListingTabs")) === null || _a === void 0 ? void 0 : _a.scrollHeight) > 4500
                    ? 1
                    : 2,
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                setPdfContent(imgData);
                clearInterval(interval);
                setLoadingProgress(100);
            });
        }, 2000);
    };
    useEffect(() => {
        if (pdfContent !== null) {
            setTimeout(() => {
                $(".downloadChat button").click();
                setLoadingData(false);
                setPdfContent(null);
            }, 2000);
        }
    }, [pdfContent]);
    useEffect(() => {
        clearDashboardSession();
        setTimeout(() => {
            const storageActiveTab = SessionStorage.getKey(SessionVariables.SELECTED_REPORTS_LISTING_TAB);
            if (storageActiveTab !== selectedTab &&
                !isEmpty(storageActiveTab) &&
                !isEmpty(tab)) {
                setSelectedTab(storageActiveTab);
            }
        }, 10);
    }, []);
    return (React.createElement(React.Fragment, null, isReportViewAllowed ? (React.createElement("div", { className: "reportsListingTabs" },
        React.createElement("div", { ref: nodeRef },
            React.createElement(Header, { leftComponent: React.createElement(SelectedListingFilters, { filters: filters }), rightComponent: React.createElement("div", { "data-html2canvas-ignore": "true" },
                    React.createElement("div", { style: { display: "inline-flex", alignItems: "center" } },
                        React.createElement("div", { className: "filterDropdown reportFilterDropdown" },
                            React.createElement(LookupDropDown, { selectedValue: selectedFilterValue, onChange: (e) => onFilterChange(e), localizationKey: ["Reports Filter"], lookUpType: LookupNames.LKReportDataFilters })),
                        React.createElement("div", { hidden: selectedFilterValue !== ReportFilterOptions.Custom, className: "customRangePicker" },
                            React.createElement(RangePicker, { picker: "month", allowClear: false, disabledDate: disabledDate, value: selectedRange.length > 0
                                    ? [
                                        moment(selectedRange[0]),
                                        moment(selectedRange[1]),
                                    ]
                                    : [null, null], onChange: (date, dateString) => {
                                    onRangeChange(date, dateString);
                                } }))),
                    React.createElement(Button, { type: "default", onClick: openFilterModal, hidden: isSingleSite },
                        React.createElement(RenderSvg, { icon: "FilterDropdown" }),
                        localize(["Report.changeSite"])),
                    React.createElement(Button, { type: "primary", onClick: loadAllData, id: "downloadBtn", key: `${loadingData}`, disabled: loadingData === true ? true : false }, loadingData === true ? "Downloading..." : "Download"),
                    React.createElement(PDFDownloadLink, { fileName: localize([
                            `Report.${(_e = tabOptions[selectedTab - 1]) === null || _e === void 0 ? void 0 : _e.titleKey}`,
                        ]), className: "downloadChat d-none", document: React.createElement(PdfContent, { image: pdfContent }) },
                        " ",
                        React.createElement(Button, { onClick: null })),
                    React.createElement(PrintButton, { printOnClick: handlePrint })) }),
            React.createElement(DownloadDataLoader, { percent: loadingProgress, loadingData: loadingData, screen: "watchlist", callBack: () => {
                    setLoadingData(false);
                    setLoadingProgress(0);
                } }),
            React.createElement(Tabs, { defaultActiveKey: selectedTab, activeKey: selectedTab, onChange: handleChangeTab }, tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.map(({ titleKey, listing }, index) => (React.createElement(TabPane, { tab: localize([`Report.${titleKey}`]), key: String(index + 1) },
                React.createElement(FinancialReportsTable, { isPrintMode: hide.status || loadingData, data: listing === null || listing === void 0 ? void 0 : listing.data, responseColumns: listing === null || listing === void 0 ? void 0 : listing.columns, titleKey: titleKey, hasChildrenExpanded: hide.status || loadingData
                        ? true
                        : titleKey === "summarySheetRatio", actions: titleKey === "summarySheetRatio" && summaryItemActions, reportType: 
                    // titleKey === "summarySheetRatio" &&
                    type, timePeriod: initialPayload.reportFilterOption, parentKey: parentKey && parentKey, getDataSheet: openDataSheetModal })))))),
        React.createElement(ReportsFiltersModel, { onClose: closeFilterModal, onSubmit: handleApplyFilter, filters: filters, setFilters: setFilters, visible: filterModalVisibility }),
        React.createElement(DataSheetsModal, { onClose: closeDataSheetModal, visible: dataSheetModalVisibility, data: excelData, formula: formula, setFormula: setFormula, exportDataSheet: exportFinancialDataSheet, selectedFilters: initialPayload, fiscalYearRange: fiscalRange }))) : (React.createElement(UnAuthorized, { subText: "permission" })
    // <Result status="403" subTitle={localize(["Report.403Msg"])} />
    )));
};
const mapDispatchToProps = {
    getLookups,
};
export default connect(null, mapDispatchToProps)(ReportsContainer);
