import React from "react";
import { AccountApi } from "../../../core/api/accountApi";
import { ResponseCodeWithMessages } from "../../../core/constants/responseStatusCodes";
import { SessionVariables } from "../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import CustomModal from "../../../layout/components/customModal";
import ResetPasswordForm from "./components/resetPasswordForm";
export const ResetPasswordContainer = ({ changePasswordModal, setChangePasswordModal, modalTitle, modalDescription, }) => {
    const handleChangePassword = (data, setErrors) => {
        AccountApi.changePassword(data).then((response) => {
            if (response.code === "OK") {
                setChangePasswordModal(false);
                if (SessionStorage.getKey(SessionVariables.PASSWORD_EXPIRES_IN)) {
                    SessionStorage.removeKey(SessionVariables.PASSWORD_EXPIRES_IN);
                }
            }
            else if (response.code ===
                ResponseCodeWithMessages.NEW_PASSWORD_ALREADY_EXISTS.key) {
                setErrors({
                    invalid: ResponseCodeWithMessages.NEW_PASSWORD_ALREADY_EXISTS.value,
                });
            }
            else if (response.code === ResponseCodeWithMessages.INVALID_USER_PASSWORD.key) {
                setErrors({
                    invalid: ResponseCodeWithMessages.INVALID_USER_PASSWORD.value,
                });
            }
            else if (response.code === ResponseCodeWithMessages.ACCOUNT_LOCKED.key) {
                setErrors({
                    invalid: ResponseCodeWithMessages.ACCOUNT_LOCKED.value,
                });
            }
            else if (response.code === ResponseCodeWithMessages.PASSWORD_RESET_TOKEN_EXPIRED.key) {
                setErrors({
                    invalid: ResponseCodeWithMessages.PASSWORD_RESET_TOKEN_EXPIRED.value,
                });
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomModal, { visible: changePasswordModal, onCancel: setChangePasswordModal, maskClosable: false, destroyOnClose: true, footer: null },
            React.createElement(ResetPasswordForm, { modalTitle: modalTitle, modalDescription: modalDescription, handleChangePassword: handleChangePassword, hideOldPassword: false }))));
};
