import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { DASHBOARD_LISTINGS_DESCRIPTION } from "../../../config/constants";
import { dashboardRoute, watchlistRoute, } from "../../../core/constants/routesPath";
import { getButtonTextKey, getBreadCrumbSession, } from "../../../core/helpers/localStorage/breadCrumbSession";
import localize from "../../../core/utils/translation-files";
import BreadcrumbNavigation from "../breadCrumbNavigation";
import PageDescription from "../common/pageDescription";
import TableActions from "../customTable/tableActions";
import RenderSvg from "../icons/RenderSvg";
import HistoryColumnDropdown from "../select/HIstoryColumnDropdown";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
// import useDashboardHierarchy from "../../../core/hooks/useDashboardHeirarchy";
import CurrentFinancialRatingBox from "../../../views/internal/shared/dashboard/DashboardRoadmap/components/currentFinancialRatingBox";
import { useHistory } from "react-router-dom";
import { createBreadcrumbArrays } from "../../../core/helpers/dasboardRoute";
import { useInitializeBreadcrumbs } from "../../../core/helpers/dasboardRoute";
const CustomPageHeader = ({ setScaleModelVisibility = null, frequencyList = null, selectedFrequency = null, setSelectedFrequency = null, handlePrint = null, descriptionText = null, pageHeading = null, itemName = null, currentRatingData = null, data = null, isHoldingCompany = false,
// setSelectedCategory = null,
 }) => {
    // const history = useHistory();
    var _a;
    // initializing breadcrumb before component renders
    useInitializeBreadcrumbs();
    const history = useHistory();
    const [buttonText, setButtonText] = useState(getButtonTextKey);
    const updateButtonText = () => {
        var _a;
        const ids = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.map((item) => parseInt(item.key));
        const level = SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        if (getButtonTextKey() === "Watchlist" &&
            ids[(ids === null || ids === void 0 ? void 0 : ids.length) - 1] !==
                SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage) &&
            level === null) {
            SessionStorage.setKey(SessionVariables.BUTTON_TEXT, "Overview", sessionStorage);
            SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage);
            setButtonText("Overview");
            SessionStorage.setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
        }
        if (level !== "Company" && level !== "Group") {
            SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        }
    };
    const headerButtons = () => {
        const btnList = [
            {
                svgIcon: "Print",
                onClick: handlePrint,
                priority: 1,
                title: ["print"],
                renderSvgButton: true,
            },
            {
                svgIcon: "RatingGraph",
                onClick: (x) => setScaleModelVisibility(true),
                priority: 2,
                title: ["Ratings"],
                btnClass: "rating-graph-btn",
                renderSvgButton: true,
            },
        ];
        return (React.createElement(TableActions, { actionArray: btnList, wrapperClass: "dottedActionsButton watchlistActions", dropdownClassName: "actionsDropdown dropdown-action-container", ShowActionText: false, menuItems: true }));
    };
    const { title } = DASHBOARD_LISTINGS_DESCRIPTION !== null && DASHBOARD_LISTINGS_DESCRIPTION !== void 0 ? DASHBOARD_LISTINGS_DESCRIPTION : {};
    const [breadcrumbs, setBreadcrumbs] = useState(SessionStorage.getKey(SessionVariables.BREADCRUMB, sessionStorage) || []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const breadcrumbArrays = createBreadcrumbArrays(breadcrumbs);
        SessionStorage.setKey(SessionVariables.BREADCRUMB_ARRAYS, breadcrumbArrays, sessionStorage);
    }, []);
    const onBackButtonEvent = (e) => {
        if (history.location.pathname.includes("dashboard")) {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            const urlPath = window.location.pathname;
            const parts = urlPath
                .split("/")
                .filter((part) => !isNaN(Number(part)) && part !== "");
            const urlToBeCompared = `/${parts.join("/")}`;
            // const path = "/" + urlToBeCompared.split("/").slice(-1).join("/");
            const breadCrumbArrays = SessionStorage.getKey(SessionVariables.BREADCRUMB_ARRAYS, sessionStorage);
            let matchingBreadcrumb;
            for (let i = 0; i < breadCrumbArrays.length; i++) {
                const breadcrumbArray = breadCrumbArrays[i];
                if (breadcrumbArray.length > 0) {
                    const lastElement = breadcrumbArray[breadcrumbArray.length - 1];
                    if (lastElement.parentPath === urlToBeCompared) {
                        matchingBreadcrumb = breadcrumbArray;
                        break; // Exit the loop once a match is found
                    }
                }
            }
            if (matchingBreadcrumb) {
                SessionStorage.setKey(SessionVariables.BREADCRUMB, matchingBreadcrumb, sessionStorage);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("popstate", onBackButtonEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            setTimeout(() => {
                window.removeEventListener("popstate", onBackButtonEvent);
            }, 500);
        };
    }, []);
    return (React.createElement("header", { className: "customHeader dashboardHeader" },
        React.createElement("span", { className: "dashboardTitleContainer", style: { display: "inline-flex", alignItems: "center" } },
            React.createElement(RenderSvg, { icon: "HomeAlt", style: { marginLeft: "0px", marginRight: "7px", marginTop: "-2px" } }),
            buttonText && (React.createElement(Button, { style: { right: "0px", marginTop: "-2px", position: "absolute" }, type: "primary", onClick: () => {
                    // clearDashboardSession();
                    if (buttonText === "Overview") {
                        history.push(dashboardRoute);
                    }
                    else if (buttonText === "Watchlist") {
                        history.push(watchlistRoute);
                    }
                } },
                buttonText === "Watchlist" && updateButtonText(),
                buttonText === "Overview"
                    ? "Back To Overview"
                    : "Back To Watchlist")),
            React.createElement("h5", null, (_a = localize(pageHeading)) !== null && _a !== void 0 ? _a : "")),
        React.createElement(BreadcrumbNavigation, { description: descriptionText }),
        React.createElement(PageDescription, { title: title, description: descriptionText, isLoading: !(data && data.length) }),
        React.createElement("div", { className: "header-action-btns" },
            React.createElement("h5", null, itemName),
            currentRatingData && isHoldingCompany && (React.createElement(CurrentFinancialRatingBox, { record: data, financialData: currentRatingData })),
            setScaleModelVisibility && (React.createElement("div", { className: "ml-auto d-flex" },
                React.createElement("div", { className: "dashboardDropdown" },
                    React.createElement("span", null, "Historical Performance Period"),
                    React.createElement(HistoryColumnDropdown, { frequencyList: frequencyList, selectedFrequency: selectedFrequency, setSelectedFrequency: setSelectedFrequency })),
                React.createElement("div", { className: "d-print-none" }, headerButtons()))))));
};
export default CustomPageHeader;
