import { Button, Tabs } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { addTrackedItemTypeRoute, } from "../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import useDidMount from "../../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import localize from "../../../../../../core/utils/translation-files";
import Header from "../../../../../../layout/components/common/header";
import PrintButton from "../../../../../../layout/components/common/PrintButton";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import ListingSearch from "../../../../../../layout/components/listingSearch";
import { getLookups } from "../../../../../../redux/lookup/actions";
import SystemCreatedTypesListingContainer from "./system-created-tracked-item-type";
import UserCreatedTypesListingContainer from "./user-created-tracked-item-type";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { browserHistoryMemoryContext } from "../../../../../../core/contexts/browserHistoryMemoryContext";
const ManageTrackedItemTypesContainer = ({ benchmarkListOptions, getLookups, }) => {
    const { TabPane } = Tabs;
    const [selectedTab, setSelectedTab] = useState(SessionStorage.getKey(SessionVariables.DEFAULTS_ACTIVE_TAB) || "1");
    const hist = useHistory();
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const history = useMemo(() => {
        return browserHistory !== null && browserHistory !== void 0 ? browserHistory : hist;
    }, [browserHistory, hist]);
    const handleChange = (activeKey) => {
        if (activeKey !== selectedTab) {
            setSelectedTab(activeKey);
            SessionStorage.setKey(SessionVariables.DEFAULTS_ACTIVE_TAB, activeKey);
            setSelectedTab(activeKey);
        }
    };
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKReportTypeBenchmark],
            getLookups,
        });
    });
    useEffect(() => {
        setTimeout(() => {
            const storageActiveTab = SessionStorage.getKey(SessionVariables.DEFAULTS_ACTIVE_TAB);
            if (storageActiveTab !== selectedTab && !isEmpty(storageActiveTab)) {
                setSelectedTab(storageActiveTab);
            }
        }, 10);
    }, []);
    const { handlePrint, nodeRef } = UsePrintPage();
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Link, { to: {
                        pathname: addTrackedItemTypeRoute,
                    } },
                    React.createElement(Button, { htmlType: "button", type: "primary" },
                        React.createElement(RenderSvg, { icon: "AddDefault" }),
                        localize(["add", "TrackedItem.default"])))) }),
        React.createElement(Tabs, { defaultActiveKey: selectedTab, activeKey: selectedTab, onChange: handleChange },
            React.createElement(TabPane, { tab: "Tracked Items Default", key: "1", id: "tracked-item-definitions" }, selectedTab === "1" && (React.createElement(UserCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions }))),
            React.createElement(TabPane, { tab: "Index/Category Defaults", key: "2", id: "tracked-item-type-defaults" },
                " ",
                selectedTab === "2" && (React.createElement(SystemCreatedTypesListingContainer, { benchmarkListOptions: benchmarkListOptions }))))));
};
function mapStateTopProps(state) {
    var _a, _b;
    return {
        benchmarkListOptions: (_b = (_a = state.lookups[LookupNames.LKReportTypeBenchmark]) === null || _a === void 0 ? void 0 : _a.map(({ text }) => text)) !== null && _b !== void 0 ? _b : [],
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(ManageTrackedItemTypesContainer);
