import React, { useEffect } from "react";
import { Button, Input, Modal } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginOutlined } from "@ant-design/icons";
import { mobileNumberRegex, passwordRegex } from "../../../../core/utils/regex";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { TERMS_CONDITION } from "../../../../core/constants/termsCondition";
import { LookupNames } from "../../../../core/constants/lookup";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import localize from "../../../../core/utils/translation-files";
import { inputDefaultLength, telephoneNumberDefaultLength, } from "../../../../core/utils/default-length";
const { confirm } = Modal;
const RegisterUserForm = ({ initialUser, RegisterUserHandler }) => {
    const LoginSchema = Yup.object({
        password: Yup.string()
            .required(localize(["password", "isRequired!"]))
            .matches(passwordRegex.regex, passwordRegex.message)
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        confirmPassword: Yup.string()
            .required(localize(["confirm", "password", "isRequired!"]))
            .oneOf([Yup.ref("password"), null], localize(["passwordsMustMatch"]))
            .matches(passwordRegex.regex, passwordRegex.message)
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        firstName: Yup.string()
            .trim()
            .required(localize(["Register.firstName", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        middleName: Yup.string()
            .trim()
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"]))
            .nullable(),
        lastName: Yup.string()
            .trim()
            .required(localize(["Register.lastName", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        emailAddress: Yup.string()
            .email(localize(["enterAValid", "email"]))
            .required(localize(["email", "address", "isRequired!"]))
            .max(inputDefaultLength, localize(["mustBe", "lessThan", `${inputDefaultLength}`, "characters"])),
        telephoneNumber: Yup.string()
            .required(localize(["mobile", "number", "isRequired!"]))
            .matches(mobileNumberRegex.regex, mobileNumberRegex.message)
            .max(telephoneNumberDefaultLength, localize([
            "mustBe",
            "lessThan",
            `${telephoneNumberDefaultLength}`,
            "characters",
        ])),
        title: Yup.string().required(localize(["Register.title", "isRequired!"])),
    });
    const { handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, } = useFormik({
        initialValues: initialUser,
        validationSchema: LoginSchema,
        onSubmit: (values, { setErrors }) => {
            showConfirm(values, setErrors);
        },
    });
    useEffect(() => {
        if (initialUser.emailAddress &&
            initialUser.firstName &&
            initialUser.lastName) {
            setFieldValue("emailAddress", initialUser.emailAddress);
            setFieldValue("title", initialUser.title);
            setFieldValue("firstName", initialUser.firstName);
            setFieldValue("middleName", initialUser.middleName);
            setFieldValue("lastName", initialUser.lastName);
            setFieldValue("companyId", initialUser.companyId);
            setFieldValue("professionId", initialUser.professionId);
            setFieldValue("status", initialUser.status);
            setFieldValue("userCompanyRoleDTOs", initialUser.userCompanyRoleDTOs);
            setFieldValue("username", initialUser.username);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUser]);
    function showConfirm(values, setErrors) {
        confirm({
            title: localize(["Register.agreementToTerms"]),
            icon: React.createElement(ExclamationCircleOutlined, null),
            content: TERMS_CONDITION.value,
            onOk() {
                values.termsAndConditionAccepted = true;
                RegisterUserHandler(values, setErrors);
            },
            onCancel() {
                values.termsAndConditionAccepted = false;
                RegisterUserHandler(values, setErrors);
            },
            okText: localize(["Register.accept"]),
            cancelText: localize(["Register.decline"]),
        });
    }
    return (React.createElement("div", { className: "mt-2" },
        React.createElement("h4", { className: "mt-0 mb-1" }, localize(["Register.registrationForm"])),
        React.createElement("p", { className: "text-color-200" }, localize(["Register.registerYourselfToGetAnAccount"])),
        React.createElement("form", { className: "mb-1 customForm", name: "basic", onSubmit: handleSubmit },
            React.createElement("div", { className: "form-group", "data-test": "title-input" },
                React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: values.title, onChange: setFieldValue, localizationKey: ["Register.title"], inputName: "title", lookUpType: LookupNames.LKTitle, error: errors.title && touched.title ? errors.title : null })),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Register.firstName"]), type: "text", name: "firstName", value: values.firstName, onChange: handleChange, className: `${errors.firstName && touched.firstName ? "input-error" : null}` }),
                errors.firstName && touched.firstName && (React.createElement("p", { className: "error" }, errors.firstName))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Register.middleName"]), type: "text", name: "middleName", value: values.middleName, onChange: handleChange, className: `${errors.middleName && touched.middleName ? "input-error" : null}` }),
                errors.middleName && touched.middleName && (React.createElement("p", { className: "error" }, errors.middleName))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Register.lastName"]), type: "text", name: "lastName", value: values.lastName, onChange: handleChange, className: `${errors.lastName && touched.lastName ? "input-error" : null}` }),
                errors.lastName && touched.lastName && (React.createElement("p", { className: "error" }, errors.lastName))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["email", "address"]), type: "email", name: "emailAddress", value: initialUser.emailAddress, onChange: handleChange, className: `${errors.emailAddress && touched.emailAddress ? "input-error" : null}`, readOnly: true }),
                errors.emailAddress && touched.emailAddress && (React.createElement("p", { className: "error" }, errors.emailAddress))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["mobile", "number"]) + " *", type: "text", name: "telephoneNumber", onBlur: handleBlur, value: values.telephoneNumber, onChange: handleChange, className: `${errors.telephoneNumber && touched.telephoneNumber
                        ? "input-error"
                        : null}` }),
                errors.telephoneNumber && touched.telephoneNumber && (React.createElement("p", { className: "error" }, errors.telephoneNumber))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["password"]) + " *", type: "password", name: "password", value: values.password, onChange: handleChange, className: `${errors.password && touched.password ? "input-error" : null}` }),
                errors.password && touched.password && (React.createElement("p", { className: "error" }, errors.password))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["confirm", "password"]) + " *", type: "password", name: "confirmPassword", value: values.confirmPassword, onChange: handleChange, className: `${errors.confirmPassword && touched.confirmPassword
                        ? "input-error"
                        : null}` }),
                errors.confirmPassword && touched.confirmPassword && (React.createElement("p", { className: "error" }, errors.confirmPassword))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LoginOutlined, { style: { fontSize: "1.3rem" } }) }, localize(["Register.register"]))))));
};
export default RegisterUserForm;
