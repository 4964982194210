import { DashboardApi } from "../api/dashboardApi";
import { unAuthorizedRoute } from "../constants/routesPath";
import { SessionVariables } from "../constants/SessionVariables";
import isEmpty from "./isEmpty";
import { SessionStorage } from "./localStorage/sessionStorage";
import { useMemo } from "react";
export const dashboardNavigation = [
    { category: "Group Dashboard" },
    { category: "Company Dashboard" },
    { category: "Site Dashboard" },
    { category: "Category Dashboard" },
    { category: "Subcategory Dashboard" },
    { category: "Tracked Item" },
];
export const getRouteByCategory = (v, groupId, companyId, params = null) => {
    return v.hierarchy === "Group"
        ? `/${v.companyId}`
        : v.hierarchy === "Company"
            ? `/${groupId}/${v.trackedItemCompanyId}`
            : v.hierarchy === "Site"
                ? `/${groupId}/${companyId}/${v.trackedItemCompanyId}`
                : v.hierarchy === "Category"
                    ? `/${groupId}/${companyId}/${groupId === v.trackedItemCompanyId ? 0 : v.trackedItemCompanyId}/${v.categoryId}`
                    : v.hierarchy === "Subcategory"
                        ? `/${groupId}/${companyId || 0}/${groupId === v.trackedItemCompanyId ||
                            companyId === v.trackedItemCompanyId
                            ? 0
                            : v.trackedItemCompanyId}/${params === null || params === void 0 ? void 0 : params.category_id}/${v.subCategoryId}`
                        : v.hierarchy === "Tracked Item" &&
                            `/${groupId}/${companyId || 0}/${groupId === v.trackedItemCompanyId ||
                                companyId === v.trackedItemCompanyId
                                ? 0
                                : v.trackedItemCompanyId}/${params === null || params === void 0 ? void 0 : params.category_id}/${params === null || params === void 0 ? void 0 : params.sub_cat_id}/${v.itemId}`;
};
const getParentsRoute = (v, groupId, companyId) => {
    return v.category === "Tracked Item"
        ? `/${groupId}/${companyId || 0}/${groupId === v.companyId ? 0 : v.companyId}/${v.categoryId}/${v.subCategoryId}/${v.id}`
        : v.category === "Subcategory"
            ? `/${groupId}/${companyId || 0}/${v.companyId}/${v.categoryId}/${v.subCategoryId}`
            : v.category === "Category"
                ? `/${groupId}/${companyId || 0}/${groupId === v.companyId || companyId === v.companyId ? 0 : v.companyId}/${v.categoryId}`
                : v.category === "Site"
                    ? `/${groupId}/${companyId}/${v.companyId || 0}`
                    : v.category === "Company"
                        ? `/${groupId}/${companyId}`
                        : v.category === "Group" && `/${v.companyId}`;
};
const addBreadcrumb = (node, groupId, companyId, siteId) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        path: node.category === "Category"
            ? isEmpty(companyId) && isEmpty(siteId)
                ? `/0/0/${node.categoryId}`
                : isEmpty(siteId)
                    ? `/0/${node.categoryId}`
                    : `/${node.categoryId}`
            : node.category === "Subcategory"
                ? `/${node.subCategoryId}`
                : node.category === "Tracked Item"
                    ? `/${node.id}`
                    : `/${node.companyId}`,
        name: (_a = node.name) !== null && _a !== void 0 ? _a : node.title,
        category: `${node.category} ${!node.category.includes("Tracked") ? "Dashboard" : ""}`,
        bandName: node.weight !== 0 && !node.weightFlag
            ? (_c = (_b = node.bandName) !== null && _b !== void 0 ? _b : node.band) !== null && _c !== void 0 ? _c : "X"
            : "E",
        bandColor: (_e = (_d = node.rgbColor) !== null && _d !== void 0 ? _d : node.bandColor) !== null && _e !== void 0 ? _e : "darkgray",
        key: (_g = (_f = node.id) !== null && _f !== void 0 ? _f : node.itemId) !== null && _g !== void 0 ? _g : node.key,
        subCategoryId: node === null || node === void 0 ? void 0 : node.subCategoryId,
        companyId: node === null || node === void 0 ? void 0 : node.companyId,
        parentPath: getParentsRoute(node, groupId, companyId),
    };
};
const getBreadcrumbsPath = (breadcrumbsData, groupId, companyId, siteId) => {
    let breadcrumbs = [];
    for (let i = 0; i < breadcrumbsData.length; i++) {
        const node = breadcrumbsData[i];
        breadcrumbs = [
            ...breadcrumbs,
            addBreadcrumb(node, groupId, companyId, siteId),
        ];
    }
    const dashboardCategories = dashboardNavigation.map((c) => c.category.trim());
    breadcrumbs = [...breadcrumbs].sort((a, b) => {
        return (dashboardCategories.indexOf(a.category.trim()) -
            dashboardCategories.indexOf(b.category.trim()));
    });
    return breadcrumbs;
};
export const createBreadcrumbArrays = (breadcrumbs) => {
    let result = JSON.parse(sessionStorage.getItem("BREADCRUMB_ARRAYS")) || [];
    for (let i = 1; i <= breadcrumbs.length; i++) {
        const currentSlice = breadcrumbs.slice(0, i);
        // Check if the current slice already exists in the result array
        const exists = result.some((array) => JSON.stringify(array) === JSON.stringify(currentSlice));
        if (!exists) {
            result.push(currentSlice);
        }
    }
    return result;
};
export const viewTrackedItemChart = (trackedItemId, filters, site_id, history, CompanyByGroup, SiteByCompany, isSingleCompanyAndSite, isSinglSite, isSingleCompany, isDashboardViewAllowed, isFinancialViewAllowed, isSummary = false) => {
    let updatedBreadcrumb;
    isDashboardViewAllowed
        ? // TrackedItemApi.getTrackedItemId(trackedItem?.type, siteId).then((res) => {
            DashboardApi.getParentData([site_id]).then((item) => {
                const { groupId, companyId, siteId } = item[site_id];
                if (!isEmpty(groupId)) {
                    DashboardApi.getTrackedItemBreadCrumb(trackedItemId).then((breadcrumbs) => {
                        var _a;
                        updatedBreadcrumb = getBreadcrumbsPath(breadcrumbs, groupId, companyId, siteId);
                        SessionStorage.setKey(SessionVariables.BREADCRUMB, updatedBreadcrumb, sessionStorage);
                        if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !== null) {
                            const ids = SessionStorage.getKey(SessionVariables.BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
                            SessionStorage.setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
                        }
                        if (groupId === siteId) {
                            return history.push(`/vertical/dashboard/${siteId || groupId}`);
                        }
                        else {
                            return history.push(`/vertical/dashboard${(_a = updatedBreadcrumb[(updatedBreadcrumb === null || updatedBreadcrumb === void 0 ? void 0 : updatedBreadcrumb.length) - 1]) === null || _a === void 0 ? void 0 : _a.parentPath}`);
                        }
                    });
                }
                else {
                    history.push(`/vertical/dashboard/${siteId || groupId}`);
                }
                // });
            })
        : history.push(unAuthorizedRoute, {
            subText: "permission",
        });
};
export const useInitializeBreadcrumbs = () => {
    useMemo(() => {
        const urlPath = window.location.pathname;
        const parts = urlPath
            .split("/")
            .filter((part) => !isNaN(Number(part)) && part !== "");
        const urlToBeCompared = `/${parts.join("/")}`;
        const breadCrumbArrays = SessionStorage.getKey(SessionVariables.BREADCRUMB_ARRAYS, sessionStorage);
        let matchingBreadcrumb;
        for (let i = 0; i < (breadCrumbArrays === null || breadCrumbArrays === void 0 ? void 0 : breadCrumbArrays.length); i++) {
            const breadcrumbArray = breadCrumbArrays[i];
            if ((breadcrumbArray === null || breadcrumbArray === void 0 ? void 0 : breadcrumbArray.length) > 0) {
                const lastElement = breadcrumbArray[(breadcrumbArray === null || breadcrumbArray === void 0 ? void 0 : breadcrumbArray.length) - 1];
                if ((lastElement === null || lastElement === void 0 ? void 0 : lastElement.parentPath) === urlToBeCompared) {
                    matchingBreadcrumb = breadcrumbArray;
                    break; // Exit the loop once a match is found
                }
            }
        }
        if (matchingBreadcrumb) {
            SessionStorage.setKey(SessionVariables.BREADCRUMB, matchingBreadcrumb, sessionStorage);
        }
    }, []); // This will execute once before the component mounts
};
