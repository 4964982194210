import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DashboardApi } from "../../../../../../core/api/dashboardApi";
import UniversalRatingTable from "./components/UniversalRatingTable";
import queryString from "query-string";
import UseCustomPagination from "../../../../../../layout/components/hooks/useCustomPagination";
import DashboardFooter from "../../components/DashboardFooter";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { getBreadCrumbSession } from "../../../../../../core/helpers/localStorage/breadCrumbSession";
import UseInfiniteScroll from "../../../../../../core/hooks/useInfiniteScroll";
import { getRouteByCategory } from "../../../../../../core/helpers/dasboardRoute";
const UniversalRating = ({ bandScales, selectedFrequency, pageSize, printInstance, userId, openNotificationModal, data, setData, handleToggleModalVisibility, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
    const params = useParams();
    const { BREADCRUMB } = SessionVariables;
    const { setKey } = SessionStorage;
    const history = useHistory();
    const paginationProps = UseCustomPagination({
        totalRecords: (_b = (_a = data === null || data === void 0 ? void 0 : data.universalTabList) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0,
        clientPagination: true,
    });
    // const handleApiCall = useCallback(
    //   (selectedWeek, param, queryParam, currentPage, printInstance) => {
    //     if (!pageSize) return;
    //     const { status: isPrintMode, resolveHandler } = printInstance ?? {};
    //     const arrayForm = (value: any[] | any) => {
    //       if (value) {
    //         return Array.isArray(value) ? value : [value];
    //       }
    //       return null;
    //     };
    //     const {
    //       reportName = null,
    //       overallBandInfoDTO = null,
    //       predictedBandInfoDTO = null,
    //       sort = null,
    //       order = null,
    //     } = queryParam;
    //     const body = {
    //       overallBands: arrayForm(overallBandInfoDTO),
    //       benchmarkCodes: null,
    //       maxOverall: null,
    //       minOverall: null,
    //       sortColumnName: null,
    //       sortOrder: null,
    //       trackedItemName: reportName,
    //       predictedBands: arrayForm(predictedBandInfoDTO),
    //     };
    //     body.sortColumnName = sort?.includes("overall") ? "Overall" : "name";
    //     body.sortOrder = order === "descend" ? "desc" : "asc";
    //     DashboardApi.getUniversalRatingData(
    //       param?.site_id,
    //       param?.sub_cat_id,
    //       selectedWeek,
    //       userId
    //     ).then((res) => {
    //       setData({
    //         listing: res?.universalTabList,
    //         totalRecords: res?.totalRecords,
    //       });
    //       setLastRefreshTime(res?.lastRefreshTime);
    //       if (isPrintMode) {
    //         resolveHandler?.();
    //       }
    //     });
    //   },
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   [pageSize, data.totalRecords, userId]
    // );
    const { search } = useLocation();
    const query = queryString.parse(search ||
        SessionStorage.getKey(SessionVariables.QUERY_PARAMS, sessionStorage));
    const handleGetApiCall = (isLoadMore = false, current = null) => {
        var _a, _b;
        const page = current !== null && current !== void 0 ? current : currentPage + 1;
        const { itemName = null, sort = null, order = null } = query !== null && query !== void 0 ? query : {};
        const body = {
            overallBands: null,
            benchmarkCodes: null,
            maxOverall: null,
            minOverall: null,
            sortColumnName: null,
            sortOrder: null,
            trackedItemName: itemName,
            predictedBands: null,
        };
        body.sortColumnName = (sort === null || sort === void 0 ? void 0 : sort.includes("itemName")) ? "name" : "band";
        body.sortOrder = order === "ascend" ? "asc" : "desc";
        const updatedPageSize = pageSize ? pageSize : 4;
        const companyId = (_b = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.at(-1)) === null || _b === void 0 ? void 0 : _b.companyId;
        if (updatedPageSize && companyId) {
            DashboardApi.getUniversalRatingData(+(params === null || params === void 0 ? void 0 : params.site_id) === 0 ? companyId : params === null || params === void 0 ? void 0 : params.site_id, +(params === null || params === void 0 ? void 0 : params.sub_cat_id), +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code), page, 4, isLoadMore ? "LoadMore" : null, body).then((res) => {
                setLastRefreshTime(res.lastRefreshTime);
                if (isLoadMore) {
                    setCurrentPage((prev) => prev + 1);
                    setData((prev) => (Object.assign(Object.assign({}, prev), { universalTabList: [
                            ...prev.universalTabList,
                            ...res.universalTabList,
                        ] })));
                    setShouldFetch(false);
                    return;
                }
                setData(res);
            });
        }
    };
    const { currentPage, setCurrentPage, setShouldFetch, handleScrollToTop } = UseInfiniteScroll({
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleGetApiCall,
        isScrollMore: ((_c = data === null || data === void 0 ? void 0 : data.universalTabList) === null || _c === void 0 ? void 0 : _c.length) < (data === null || data === void 0 ? void 0 : data.totalRecords),
    });
    useEffect(() => {
        if (selectedFrequency && (params === null || params === void 0 ? void 0 : params.site_id)) {
            // handleApiCall(
            //   selectedFrequency.code,
            //   params,
            //   query,
            //   paginationProps?.currentPage,
            //   printInstance
            // );
            handleScrollToTop();
            setCurrentPage(1);
            handleGetApiCall(false, 1);
            // DashboardApi.getUniversalRatingData(
            //   +params?.site_id,
            //   +params?.sub_cat_id,
            //   selectedFrequency.code
            // ).then((res) => {
            //   setData(res);
            //   // setData({
            //   //   listing: res?.universalTabList,
            //   //   totalRecords: res?.totalRecords,
            //   // });
            //   setLastRefreshTime(res?.lastRefreshTime);
            //   // if (isPrintMode) {
            //   //   resolveHandler?.();
            //   // }
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params,
        selectedFrequency,
        query === null || query === void 0 ? void 0 : query.sort,
        query === null || query === void 0 ? void 0 : query.order,
        query === null || query === void 0 ? void 0 : query.itemName,
        (_e = (_d = getBreadCrumbSession()) === null || _d === void 0 ? void 0 : _d.at(-1)) === null || _e === void 0 ? void 0 : _e.companyId,
    ]);
    const handleRowClick = (record) => {
        const { itemId, trackedItemCompanyId, itemName, hierarchy, currentRatingInfoDTO, } = record;
        const breadCrumbList = getBreadCrumbSession();
        // setPrevBreadcrumbPath(breadCrumbList);
        DashboardApi.getParentData([trackedItemCompanyId]).then((res) => {
            const { groupId, companyId } = res[trackedItemCompanyId];
            setKey(BREADCRUMB, [
                ...breadCrumbList,
                {
                    path: `/${itemId}`,
                    key: itemId,
                    name: itemName,
                    category: hierarchy,
                    bandName: (record === null || record === void 0 ? void 0 : record.weight) !== 0 && !(record === null || record === void 0 ? void 0 : record.weightFlag)
                        ? currentRatingInfoDTO === null || currentRatingInfoDTO === void 0 ? void 0 : currentRatingInfoDTO.currentStatus
                        : "E",
                    bandColor: currentRatingInfoDTO === null || currentRatingInfoDTO === void 0 ? void 0 : currentRatingInfoDTO.currentStatusRgb,
                    parentPath: getRouteByCategory(record, groupId, companyId, params),
                    companyId: trackedItemCompanyId,
                },
            ], sessionStorage);
            if (SessionStorage.getKey(SessionVariables.EXPANDED_KEYS, sessionStorage) !== null) {
                const ids = SessionStorage.getKey(BREADCRUMB, sessionStorage).map((item) => parseInt(item.key));
                setKey(SessionVariables.EXPANDED_KEYS, ids === null || ids === void 0 ? void 0 : ids.slice(0, -1), sessionStorage);
            }
            history.push(`${history === null || history === void 0 ? void 0 : history.location.pathname}/${+itemId}`, {
                trackedItemId: itemId,
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(UniversalRatingTable, { data: (_f = data === null || data === void 0 ? void 0 : data.universalTabList) !== null && _f !== void 0 ? _f : [], handleRowClick: handleRowClick, scaleBandArray: bandScales, selectedFrequency: selectedFrequency, customTablePropChange: paginationProps.handleCustomTablePropChange, openNotificationModal: openNotificationModal, handleToggleWatchListModal: handleToggleModalVisibility, userId: userId }),
        React.createElement(DashboardFooter, { lastRefreshTime: lastRefreshTime })));
};
export default UniversalRating;
