import { useEffect, useRef, useState } from "react";
import isEmpty from "../helpers/isEmpty";
const UseToggleStateOnScroll = () => {
    const [visible, setVisible] = useState(false);
    const formTopRef = useRef(null);
    const removeTableAction = (event) => {
        var _a, _b, _c, _d, _e, _f;
        const shouldClose = formTopRef.current !==
            ((_b = (_a = document.querySelector(".form-group")) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.top);
        // console.log({
        //   shouldClose,
        //   id: event.target?.getAttribute("id"),
        //   // current: event.currentTarget?.getAttribute("id"),
        //   chh:
        //     shouldClose &&
        //     event.target?.getAttribute("id") !== "option-div" &&
        //     event.target?.getAttribute("id") !== "option-div-container",
        // });
        if (
        //  isEmpty(event.target?.getAttribute("id")) ||
        shouldClose &&
            ((_c = event.target) === null || _c === void 0 ? void 0 : _c.getAttribute("id")) !== "option-div" &&
            ((_d = event.target) === null || _d === void 0 ? void 0 : _d.getAttribute("id")) !== "option-div-container") {
            visible && setVisible(false);
        }
        formTopRef.current = (_f = (_e = document
            .querySelector(".form-group")) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect()) === null || _f === void 0 ? void 0 : _f.top;
    };
    useEffect(() => {
        if (isEmpty(formTopRef.current) && document.querySelector(".form-group")) {
            formTopRef.current = document
                .querySelector(".form-group")
                .getBoundingClientRect().top;
        }
    }, [formTopRef.current]);
    useEffect(() => {
        window.addEventListener("wheel", removeTableAction);
        return () => {
            window.removeEventListener("wheel", removeTableAction, false);
        };
    }, [visible, formTopRef.current]);
    return { visible, setVisible };
};
export default UseToggleStateOnScroll;
