import { Tooltip } from "antd";
import React from "react";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import CurrentFinancialRatingBox from "./components/currentFinancialRatingBox";
const TreeTableName = ({ record, fullData, bandData, navigateToDashboard, notificationCountListId, openNotificationModal, roadmapActionButton, handleToggleWatchListModal, financialData, }) => {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "rowColorBoxcontainer" },
            ((_a = fullData[0]) === null || _a === void 0 ? void 0 : _a.category) === "Group" && (React.createElement("span", { className: "row-level-0-box row-box groupNodeBox" })),
            (((_b = fullData[0]) === null || _b === void 0 ? void 0 : _b.category) === "Company" ||
                ((_c = fullData[0]) === null || _c === void 0 ? void 0 : _c.category) === "Group") && (React.createElement("span", { className: "row-level-1-box row-box companyNodeBox" })),
            (((_d = fullData[0]) === null || _d === void 0 ? void 0 : _d.category) === "Company" ||
                ((_e = fullData[0]) === null || _e === void 0 ? void 0 : _e.category) === "Group" ||
                ((_f = fullData[0]) === null || _f === void 0 ? void 0 : _f.category) === "Site") && (React.createElement("span", { className: "row-level-2-box row-box siteNodeBox" })),
            React.createElement("span", { className: "row-level-3-box row-box categoryNodeBox" }),
            React.createElement("span", { className: "row-level-4-box row-box subcategoryNodeBox" }),
            React.createElement("span", { className: "row-level-5-box row-box trackedItemNodeBox" })),
        React.createElement("div", { className: "treeNameColumn" },
            React.createElement("span", { className: "treeTitle", onClick: (e) => navigateToDashboard(record), style: {
                    marginRight: "10px",
                } },
                React.createElement(Tooltip, { placement: "top", title: `Go to ${record.category} Dashboard` }, record.title)),
            (record === null || record === void 0 ? void 0 : record.category) === "Group" &&
                ((record === null || record === void 0 ? void 0 : record.isHoldingCompany) ? (React.createElement(RenderSvg, { icon: "HoldingGroup", tooltipTitle: "Non-Subsidiary Reliant Group", style: { display: "block", color: "#1B998F" } })) : (React.createElement(RenderSvg, { icon: "NonHoldingGroup", tooltipTitle: "Subsidiary Reliant Group", style: { display: "block", color: "#1B998F" } }))),
            React.createElement("span", { className: "d-print-none roadmapActions" }, roadmapActionButton(record)),
            ((record.category === "Group" && (bandData === null || bandData === void 0 ? void 0 : bandData.isHoldingCompany)) ||
                record.category === "Company") && (React.createElement(CurrentFinancialRatingBox, { record: record, 
                // placement={record.category === "Group" ? "top" : "right"}
                financialData: financialData, navigateCallBack: navigateToDashboard })))));
};
export default TreeTableName;
