export class SessionVariables {
}
SessionVariables.SELECTED_TRACKED_ITEM_INDEX = "SELECTED_TRACKED_ITEM_INDEX";
SessionVariables.SELECTED_TRACKED_ITEM_TYPE = "SELECTED_TRACKED_ITEM_TYPE";
SessionVariables.SELECTED_LOOKUP_TYPE = "SELECTED_LOOKUP_TYPE";
SessionVariables.SELECTED_TILE_INDEX = "SELECTED_TILE_INDEX";
SessionVariables.SITE_OVERVIEW_DATA = "SITE_OVERVIEW_DATA";
SessionVariables.SELECTED_MANAGE_TRACKED_ITEM_TAB = "SELECTED_MANAGE_TRACKED_ITEM_TAB";
SessionVariables.SELECTED_USER_LISTING_TAB = "SELECTED_USER_LISTING_TAB";
SessionVariables.SELECTED_NOTIFICATIONS_LISTING_TAB = "SELECTED_NOTIFICATIONS_LISTING_TAB";
SessionVariables.SELECTED_REPORTS_LISTING_TAB = "SELECTED_REPORTS_LISTING_TAB";
SessionVariables.AUTH_TOKEN = "AUTH_TOKEN";
SessionVariables.REFRESH_TOKEN = "REFRESH_TOKEN";
SessionVariables.PI_TOKEN = "PI_TOKEN";
SessionVariables.GROUP = "GROUP";
SessionVariables.COMPANY = "COMPANY";
SessionVariables.USER_CONTEXT = "USER_CONTEXT";
SessionVariables.SELECTED_TASK = "SELECTED_TASK";
SessionVariables.SITE_STATUSES = "SITE_STATUSES";
SessionVariables.PASSWORD_EXPIRES_IN = "password_expires_in";
SessionVariables.OLD_PASSWORD = "oldPassword";
SessionVariables.USERNAME = "username";
SessionVariables.USER_FILTER = "USER_FILTER";
SessionVariables.SELECTED_TRACKED_ITEM_DASHBOARD_RATING = "SELECTED_TRACKED_ITEM_DASHBOARD_RATING";
SessionVariables.SELECTED_CHART_FILTER = "SELECTED_CHART_FILTER";
SessionVariables.BREADCRUMB = "BREADCRUMB";
SessionVariables.WATCHLIST_ITEM_ID = "WATCHLIST_ITEM_ID";
SessionVariables.WATCHLIST_ITEM_LEVEL = "WATCHLIST_ITEM_LEVEL";
SessionVariables.WATCHLIST_TAB = "WATCHLIST_TAB";
SessionVariables.TREE_EXPANDED_STATE = "TREE_EXPANDED_STATE";
SessionVariables.EXPANDED_KEYS = "EXPANDED_KEYS";
SessionVariables.QUERY_PARAMS = "QUERY_PARAMS";
SessionVariables.SELECTED_SUB_CATEGORY = "SELECTED_SUB_CATEGORY";
SessionVariables.SELECTED_CATEGORY = "SELECTED_CATEGORY";
SessionVariables.MENU_SESSION = "MENU_SESSION";
SessionVariables.LISTING_FILTERS = "LISTING_FILTERS";
SessionVariables.RECENT_NOTIFICATIONS = "RECENT_NOTIFICATIONS";
SessionVariables.FINANCIAL_MODULE = "FINANCIAL_MODULE";
SessionVariables.NOTIFICATION_PARAMETERS = "NOTIFICATION_PARAMETERS";
SessionVariables.WATCHLIST_ACTIVE_TAB = "WATCHLIST_ACTIVE_TAB";
SessionVariables.DEFAULTS_ACTIVE_TAB = "DEFAULTS_ACTIVE_TAB";
SessionVariables.OVERVIEW_API_COUNT = "OVERVIEW_API_COUNT";
SessionVariables.BUTTON_TEXT = "BUTTON_TEXT";
SessionVariables.REPORTS_TAB_FILTER = "REPORTS_TAB_FILTER";
SessionVariables.LAST_EXPANDED_KEY = "LAST_EXPANDED_KEY";
SessionVariables.WATCHLIST_PREVIOUS_TAB = "WATCHLIST_PREVIOUS_TAB";
SessionVariables.USER_LISTING_PREVIOUS_TAB = "USER_LISTING_PREVIOUS_TAB";
SessionVariables.TRACKED_ITEMS_PREVIOUS_TAB = "TRACKED_ITEMS_PREVIOUS_TAB";
SessionVariables.DEFAULTS_PREVIOUS_TAB = "DEFAULTS_PREVIOUS_TAB";
SessionVariables.REPORTS_PREVIOUS_TAB = "REPORTS_PREVIOUS_TAB";
SessionVariables.NOTIFICATIONS_PREVIOUS_TAB = "NOTIFICATIONS_PREVIOUS_TAB";
SessionVariables.BREADCRUMB_PREVIOUS_PATH = "BREADCRUMB_PREVIOUS_PATH";
SessionVariables.COMPANIES_LISTING_DATA = "COMPANIES_LISTING_DATA";
SessionVariables.UNREAD_NOTIFICATION_COUNT = "UNREAD_NOTIFICATION_COUNT";
SessionVariables.LAST_POPUP_BREADCRUMB = "LAST_POPUP_BREADCRUMB";
SessionVariables.DEFAULT_WATCHLIST_TAB = "DEFAULT_WATCHLIST_TAB";
SessionVariables.BREADCRUMB_ARRAYS = "BREADCRUMB_ARRAYS";
