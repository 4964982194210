import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import CustomTooltip from "../customTooltip";
import RenderSvg from "../icons/RenderSvg";
import { Badge } from "antd";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
const SimpleItem = ({ routing, title, layout, icon, isAdmin, adminTitle, child, show = true, resetSideBar, expanded, svgIcon, }) => {
    const location = useLocation();
    const unreadNotificationCount = SessionStorage.getKey(SessionVariables.UNREAD_NOTIFICATION_COUNT);
    const renderSvgIcon = () => {
        if (svgIcon) {
            if (typeof svgIcon === "object") {
                if (routing === "notifications-events" && !expanded) {
                    return (React.createElement(Badge, { className: "notification-count-badge", count: unreadNotificationCount },
                        React.createElement("span", { className: "notification-icon" },
                            React.createElement(RenderSvg, { icon: location.pathname.split("/")[2] === routing
                                    ? svgIcon.active
                                    : svgIcon.inActive }))));
                }
                return (React.createElement(RenderSvg, { icon: location.pathname.split("/")[2] === routing
                        ? svgIcon.active
                        : svgIcon.inActive }));
            }
            return React.createElement(RenderSvg, { icon: svgIcon });
        }
        return React.createElement(FontAwesomeIcon, { icon: icon, size: "lg" });
    };
    return expanded === true ? (React.createElement(React.Fragment, null,
        React.createElement("li", { className: "menu-item" },
            React.createElement(NavLink, { id: title.toLowerCase().replaceAll(" ", "-"), className: "item-link", to: `/${layout}/${routing}`, activeClassName: "active", onClick: (event) => {
                    expanded && resetSideBar();
                }, style: { justifyContent: expanded ? "space-between" : "center" } },
                React.createElement("span", null, renderSvgIcon()),
                React.createElement("span", { className: `link-text`, hidden: !expanded }, isAdmin === true || adminTitle === undefined ? title : adminTitle))))) : (React.createElement(React.Fragment, null,
        React.createElement(CustomTooltip, { placement: "right", renderTooltip: !expanded, title: isAdmin === true || adminTitle === undefined ? title : adminTitle },
            React.createElement("li", { className: "menu-item" },
                React.createElement(NavLink, { id: title.toLowerCase().replaceAll(" ", "-"), className: "item-link", to: `/${layout}/${routing}`, activeClassName: "active", onClick: (event) => {
                        expanded && resetSideBar();
                    }, style: { justifyContent: expanded ? "space-between" : "center" } },
                    React.createElement("span", null, renderSvgIcon()),
                    React.createElement("span", { className: `link-text`, hidden: !expanded }, isAdmin === true || adminTitle === undefined
                        ? title
                        : adminTitle))))));
};
export default SimpleItem;
