import React from "react";
import Logo from "../logo/logo";
import LogoPng from "../../../assets/img/delphieLogo.png";
import { SPRINT_VERSION } from "../../../core/constants/termsCondition";
import usePermissions from "../../../core/hooks/usePermissions";
import { UserType } from "../../../core/enums/UserStatus";
export const TopNavbar = () => {
    var _a;
    const { singleSiteValues, singleCompanyValues, singleGroupValues, context } = (_a = usePermissions()) !== null && _a !== void 0 ? _a : {};
    const getCompanyContext = () => {
        var _a, _b, _c;
        if (context.userType === UserType.External) {
            return "RodaBI By CCQS";
        }
        return ((_c = (_b = (_a = singleSiteValues === null || singleSiteValues === void 0 ? void 0 : singleSiteValues.name) !== null && _a !== void 0 ? _a : singleCompanyValues === null || singleCompanyValues === void 0 ? void 0 : singleCompanyValues.name) !== null && _b !== void 0 ? _b : singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.name) !== null && _c !== void 0 ? _c : context === null || context === void 0 ? void 0 : context.userCompanyName);
    };
    return (React.createElement("div", { className: "topNavBar" },
        React.createElement("div", null,
            React.createElement(Logo, { src: LogoPng, logoText: getCompanyContext() })),
        React.createElement("div", { className: "d-flex align-items-center  " },
            React.createElement("span", { className: "text-align-right" },
                "CCQS version ",
                SPRINT_VERSION))));
};
