import { Button, Radio, Tabs } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useImperativeHandle, useState } from "react";
import * as Yup from "yup";
import localize from "../../../../core/utils/translation-files";
import Asterik from "../../../internal/shared/helper-components/Asterik";
import { notificationOpt, NOTIFICATION_TYPE_COLLECTIVE, timeOpt, NOTIFICATION_TYPE_INDIVIDUAL, } from "../../../../core/constants/notificationSettingsConstants";
import SingleSelectNew from "../../../../layout/components/select/SingleSelectNew";
import LookupDropDown from "../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../core/constants/lookup";
import { arePropertiesEmpty } from "../../../../core/helpers/isEmpty";
import PromptComponent from "../../../../layout/components/prompt-message";
import isActionAllowed from "../../../../core/authorization/userPermissions";
import { REPORT_CATEGORY_CARE_DELIVERY, REPORT_CATEGORY_CARE_FINANCIALS, } from "../../../../core/constants/permissionConstants";
const NotificationSettingsForm = ({ initialNotificationSetting, onSubmit, modalDescription, modalTitle, closeModal, tabOptions, valuesRef, initialValues, }) => {
    var _a, _b;
    const { TabPane } = Tabs;
    const [selectedTab, setSelectedTab] = useState((_a = tabOptions[0]) === null || _a === void 0 ? void 0 : _a.code);
    const [showError, setShowError] = useState(false);
    let SettingsSchema = Yup.array().of(Yup.object({
        notificationType: Yup.number().required(localize(["Field", "isRequired!"])),
        notificationFrequency: Yup.number().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.number()
                .required(localize(["Notification.frequency", "isRequired!"]))
                .nullable(),
            otherwise: Yup.number().nullable(),
        }),
        timeInterval: Yup.string().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.string()
                .required(localize(["Notification.time", "isRequired!"]))
                .nullable(),
            otherwise: Yup.string().nullable(),
        }),
        sortId: Yup.string().when("notificationType", {
            is: NOTIFICATION_TYPE_COLLECTIVE,
            then: Yup.string()
                .required(localize(["Notification.sort", "isRequired!"]))
                .nullable(),
            otherwise: Yup.string().nullable(),
        }),
    }));
    const { handleSubmit, values, errors, dirty, setFieldValue, resetForm, setValues, } = useFormik({
        initialValues: initialNotificationSetting,
        validationSchema: SettingsSchema,
        onSubmit: (values) => {
            var _a, _b;
            if ((tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 2) {
                onSubmit(values, resetForm);
            }
            else if ((tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1) {
                if (((_a = tabOptions[0]) === null || _a === void 0 ? void 0 : _a.permissionCode) === REPORT_CATEGORY_CARE_DELIVERY) {
                    // case when initially both permissions and one permission removed (2 records will be returning from BE)
                    if ((values === null || values === void 0 ? void 0 : values.length) > 1) {
                        const carePayload = values === null || values === void 0 ? void 0 : values.filter((value) => value.categoryTypeId === 0);
                        onSubmit(carePayload, resetForm);
                    }
                    // case when only one permission - entering new record
                    if (((_b = values[0]) === null || _b === void 0 ? void 0 : _b.id) === 0) {
                        onSubmit(values, resetForm);
                    }
                }
                else {
                    if ((values === null || values === void 0 ? void 0 : values.length) > 1) {
                        const financialPayload = values.filter((value) => value.categoryTypeId === 2);
                        onSubmit(financialPayload, resetForm);
                    }
                    onSubmit(values, resetForm);
                }
            }
        },
    });
    const handleCancel = () => {
        closeModal(resetForm);
    };
    const setValuess = (val, field) => {
        setShowError(false);
        setFieldValue(field, val);
    };
    useImperativeHandle(valuesRef, () => {
        return {
            isPrompt: dirty,
        };
    }, [dirty]);
    useEffect(() => {
        var _a;
        if (initialNotificationSetting && initialNotificationSetting.length) {
            setValues(initialNotificationSetting);
            ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.notificationType) === NOTIFICATION_TYPE_COLLECTIVE &&
                tabOptions &&
                tabOptions.map((v, i) => setValuess(NOTIFICATION_TYPE_COLLECTIVE, `${i}.notificationType`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialNotificationSetting]);
    return (React.createElement("div", { className: "reset-password", "data-test": "resetPasswordForm" },
        React.createElement("h4", { className: "mt-0 mb-2" }, modalTitle),
        React.createElement("p", { className: "text-color-200" }, modalDescription),
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: `mb-1 customForm`, name: "basic", onSubmit: handleSubmit },
            React.createElement("div", null,
                React.createElement("div", { className: "ant-col ant-form-item-label" },
                    React.createElement("label", null,
                        " ",
                        localize(["Notification.notificationType"]),
                        initialNotificationSetting &&
                            initialNotificationSetting.length ? (React.createElement(Asterik, null)) : null,
                        " ")),
                React.createElement("div", { className: "form-group" }, (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) ? (React.createElement(Radio.Group, { onChange: (e) => {
                        tabOptions && tabOptions.length
                            ? tabOptions.map((value, index) => {
                                setValuess(e.target.value, `${index}.notificationType`);
                                if (e.target.value === 0) {
                                    setValuess(null, `${index}.notificationFrequency`);
                                    setValuess(null, `${index}.timeInterval`);
                                    setValuess(null, `${index}.sortId`);
                                }
                                return 0;
                            })
                            : setValuess(e.target.value, `${0}.notificationType`);
                    }, defaultValue: (_b = values[0]) === null || _b === void 0 ? void 0 : _b.notificationType, buttonStyle: "solid", name: "notificationType" },
                    " ",
                    notificationOpt.map(({ value, text }, index) => {
                        var _a;
                        return ((value === 0 ||
                            (value === 1 &&
                                isActionAllowed(REPORT_CATEGORY_CARE_FINANCIALS)) ||
                            isActionAllowed(REPORT_CATEGORY_CARE_DELIVERY)) && (React.createElement(Radio, { value: value, key: value, disabled: !(tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) &&
                                ((_a = initialNotificationSetting[0]) === null || _a === void 0 ? void 0 : _a.notificationType) ===
                                    NOTIFICATION_TYPE_INDIVIDUAL },
                            text,
                            " Notification")));
                    }))) : (React.createElement("div", null, "Individual Notification")))),
            tabOptions && tabOptions.length ? (React.createElement("div", null,
                React.createElement(Tabs, { defaultActiveKey: selectedTab, onChange: setSelectedTab }, tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.map(({ code, text, permissionCode }, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                    return (React.createElement(TabPane, { tab: text, key: code },
                        React.createElement("div", { className: " notificationSettingsFrequency" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.frequency"]),
                                            React.createElement(Asterik, null))), values === null || values === void 0 ? void 0 :
                                    values.notificationType,
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                            permissionCode === REPORT_CATEGORY_CARE_FINANCIALS
                                            ? (_a = values.find((value) => (value === null || value === void 0 ? void 0 : value.categoryTypeId) === 2)) === null || _a === void 0 ? void 0 : _a.notificationFrequency
                                            : (values === null || values === void 0 ? void 0 : values.length) === 1 &&
                                                ((_b = values[0]) === null || _b === void 0 ? void 0 : _b.categoryTypeId) === 2
                                                ? undefined
                                                : (_c = values[index]) === null || _c === void 0 ? void 0 : _c.notificationFrequency, onChange: (val, obj) => {
                                            var _a;
                                            if ((tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                                permissionCode ===
                                                    REPORT_CATEGORY_CARE_FINANCIALS &&
                                                (values === null || values === void 0 ? void 0 : values.length) > 1 &&
                                                ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.keyType) !== null) {
                                                setValuess(obj, `${values.findIndex((value) => value.categoryTypeId === 2)}.notificationFrequency`);
                                            }
                                            setValuess(obj, `${index}.notificationFrequency`);
                                        }, inputName: "notificationFrequency", localizationKey: ["Notification.frequency"], disabled: ((_d = values[index]) === null || _d === void 0 ? void 0 : _d.notificationType) ===
                                            NOTIFICATION_TYPE_INDIVIDUAL, lookUpType: LookupNames.LKCollectiveNotificationFrequency, error: errors[index] &&
                                            errors[index]["notificationFrequency"] &&
                                            showError
                                            ? errors[index]["notificationFrequency"]
                                            : null }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.time"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(SingleSelectNew, { key: "timeInterval", allowClear: false, selectedValue: (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                            permissionCode === REPORT_CATEGORY_CARE_FINANCIALS
                                            ? (_f = (_e = values
                                                .find((value) => value.categoryTypeId === 2)) === null || _e === void 0 ? void 0 : _e.timeInterval) === null || _f === void 0 ? void 0 : _f.slice(0, 5)
                                            : (values === null || values === void 0 ? void 0 : values.length) === 1 &&
                                                ((_g = values[0]) === null || _g === void 0 ? void 0 : _g.categoryTypeId) === 2
                                                ? undefined
                                                : (_j = (_h = values[index]) === null || _h === void 0 ? void 0 : _h.timeInterval) === null || _j === void 0 ? void 0 : _j.slice(0, 5), className: errors.timeInterval && showError
                                            ? "require-ant-select"
                                            : "", placeholder: "Select a Time", onChange: (val, obj) => {
                                            var _a;
                                            if ((tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                                permissionCode ===
                                                    REPORT_CATEGORY_CARE_FINANCIALS &&
                                                (values === null || values === void 0 ? void 0 : values.length) > 1 &&
                                                ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.keyType) !== null) {
                                                const financialIndex = values.findIndex((value) => value.categoryTypeId === 2);
                                                setValuess(obj.text, `${financialIndex}.timeInterval`);
                                                setValuess(4, `${financialIndex}.sortId`);
                                            }
                                            setValuess(obj.text, `${index}.timeInterval`);
                                            setValuess(4, `${index}.sortId`);
                                        }, disabled: ((_k = values[index]) === null || _k === void 0 ? void 0 : _k.notificationType) ===
                                            NOTIFICATION_TYPE_INDIVIDUAL, options: timeOpt, valueProperty: "value", textProperty: "text", error: errors[index] &&
                                            errors[index]["timeInterval"] &&
                                            showError &&
                                            errors[index]["timeInterval"] }))),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col ant-form-item-label" },
                                        React.createElement("label", null,
                                            localize(["Notification.sortBy"]),
                                            React.createElement(Asterik, null))),
                                    React.createElement(LookupDropDown, { className: "mb-2 mb-md-0", selectedValue: (tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                            permissionCode === REPORT_CATEGORY_CARE_FINANCIALS
                                            ? (_l = values.find((value) => value.categoryTypeId === 2)) === null || _l === void 0 ? void 0 : _l.sortId
                                            : (values === null || values === void 0 ? void 0 : values.length) === 1 &&
                                                ((_m = values[0]) === null || _m === void 0 ? void 0 : _m.categoryTypeId) === 2
                                                ? undefined
                                                : (_o = values[index]) === null || _o === void 0 ? void 0 : _o.sortId, onChange: (val, obj) => {
                                            var _a;
                                            if ((tabOptions === null || tabOptions === void 0 ? void 0 : tabOptions.length) === 1 &&
                                                permissionCode ===
                                                    REPORT_CATEGORY_CARE_FINANCIALS &&
                                                (values === null || values === void 0 ? void 0 : values.length) >= 1 &&
                                                ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.keyType) !== null) {
                                                setValuess(obj, `${values.findIndex((value) => value.categoryTypeId === 2)}.sortId`);
                                            }
                                            setValuess(obj, `${index}.sortId`);
                                        }, inputName: "sortId", localizationKey: ["Notification.sort"], disabled: ((_p = values[index]) === null || _p === void 0 ? void 0 : _p.notificationType) ===
                                            NOTIFICATION_TYPE_INDIVIDUAL, lookUpType: LookupNames.LKCollectiveNotificationSort, error: errors[index] &&
                                            errors[index]["sortId"] &&
                                            showError
                                            ? errors[index]["sortId"]
                                            : null }))),
                            !arePropertiesEmpty(errors) && showError ? (React.createElement("p", { className: "error", style: { position: "inherit" } }, localize([
                                "Notification.allCategories",
                                "areRequired!",
                            ]))) : null)));
                })))) : null,
            React.createElement("div", { className: "form-group row justify-content-between align-items-center" },
                React.createElement(Button, { type: "default", onClick: handleCancel }, localize(["Cancel"])),
                React.createElement(Button, { type: "primary", htmlType: "submit", onClick: () => setShowError(true), disabled: !dirty ? true : false }, localize(["Save"]))))));
};
export default NotificationSettingsForm;
