import { Button, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import LookupDropDown from "../../../../../../../layout/components/select/LookupDropDown";
import { LookupNames } from "../../../../../../../core/constants/lookup";
import moment from "moment";
import { ChartFilterOptions } from "../../../../../../../core/enums/chart";
import { disabledDate } from "../../../../../../../core/validations/dateTimeValidations";
import TableActions from "../../../../../../../layout/components/customTable/tableActions";
import { dashboardRoute, watchlistRoute, } from "../../../../../../../core/constants/routesPath";
import { SessionVariables } from "../../../../../../../core/constants/SessionVariables";
import { SessionStorage } from "../../../../../../../core/helpers/localStorage/sessionStorage";
import { createBreadcrumbArrays } from "../../../../../../../core/helpers/dasboardRoute";
import { useInitializeBreadcrumbs } from "../../../../../../../core/helpers/dasboardRoute";
function ChartActionButtons({ printHandle, chartVariables, openScaleModal, setSelectedFilterValue, selectedFilterValue, setSelectedRange, setChartData, selectedRange, chartData, trackedItemId, exportHandle, exportFinancialSheet = null, browserHistory = null, isDashboard = false, hideBackButton = false, buttonText = null, updateButtonText = null, isSummary = false, viewFinancialModule, watchlistStatus = false, isFinancial, handleToggleChartModalVisibilty = null, handleToggleExportModalVisibilty = null, isNotification = false, handleToggleWatchListModal = null, }) {
    useInitializeBreadcrumbs();
    const { RangePicker } = DatePicker;
    const history = useHistory();
    const onFilterChange = (value) => {
        if (value !== ChartFilterOptions.Custom) {
            setChartData && setChartData();
        }
        setSelectedRange([]);
        setSelectedFilterValue(value);
    };
    const onRangeChange = (date, dateString) => {
        setChartData && setChartData();
        setSelectedRange(dateString);
    };
    const params = useParams();
    const [breadcrumbs, setBreadcrumbs] = useState(SessionStorage.getKey(SessionVariables.BREADCRUMB, sessionStorage) || []);
    useEffect(() => {
        const breadcrumbArrays = createBreadcrumbArrays(breadcrumbs);
        SessionStorage.setKey(SessionVariables.BREADCRUMB_ARRAYS, breadcrumbArrays, sessionStorage);
    }, []);
    const onBackButtonEvent = (e) => {
        if (history.location.pathname.includes("dashboard")) {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            const urlPath = window.location.pathname;
            const parts = urlPath
                .split("/")
                .filter((part) => !isNaN(Number(part)) && part !== "");
            const urlToBeCompared = `/${parts.join("/")}`;
            // const path = "/" + urlToBeCompared.split("/").slice(-1).join("/");
            const breadCrumbArrays = SessionStorage.getKey(SessionVariables.BREADCRUMB_ARRAYS, sessionStorage);
            let matchingBreadcrumb;
            for (let i = 0; i < breadCrumbArrays.length; i++) {
                const breadcrumbArray = breadCrumbArrays[i];
                if (breadcrumbArray.length > 0) {
                    const lastElement = breadcrumbArray[breadcrumbArray.length - 1];
                    if (lastElement.parentPath === urlToBeCompared) {
                        matchingBreadcrumb = breadcrumbArray;
                        break; // Exit the loop once a match is found
                    }
                }
            }
            if (matchingBreadcrumb) {
                SessionStorage.setKey(SessionVariables.BREADCRUMB, matchingBreadcrumb, sessionStorage);
            }
        }
    };
    useEffect(() => {
        window.addEventListener("popstate", onBackButtonEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            setTimeout(() => {
                window.removeEventListener("popstate", onBackButtonEvent);
            }, 500);
        };
    }, []);
    const menuActionButton = () => {
        var _a, _b;
        const btnList = [
            {
                svgIcon: "Print",
                onClick: printHandle,
                priority: 1,
                title: ["print"],
                renderSvgButton: true,
            },
            {
                svgIcon: watchlistStatus ? "AddedToWatchlist" : "WatchlistButton",
                onClick: handleToggleWatchListModal,
                //isWatchListStatus ? removeFromWatchlist(item) : addToWatchList(item),
                priority: 1,
                title: ["Add / Remove Watchlist"],
                renderSvgButton: true,
            },
            {
                disbaled: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight) === 0,
                onClick: openScaleModal,
                svgIcon: "RatingGraph",
                btnClass: "rating-graph-icon",
                renderSvgButton: true,
                priority: 2,
                title: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.weight) !== 0 ? ["Ratings"] : ["Rating - Unratable"],
            },
            {
                onClick: handleToggleChartModalVisibilty,
                priority: 3,
                title: ["TrackedItem.chartVariables"],
                iconComponent: (React.createElement(InfoCircleOutlined, { style: {
                        fontSize: "16px",
                    } })),
            },
            {
                onClick: () => exportHandle(selectedFilterValue, chartData, trackedItemId, selectedRange, chartVariables),
                priority: 4,
                title: ["Button.graphDataInTable"],
                svgIcon: "TableFormat",
                renderSvgButton: true,
                disbaled: !((_a = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _a === void 0 ? void 0 : _a.length),
            },
            {
                onClick: () => isFinancial
                    ? exportFinancialSheet(selectedFilterValue, trackedItemId, selectedRange, chartVariables)
                    : handleToggleExportModalVisibilty(),
                priority: 5,
                title: ["Button.relatedItemData"],
                svgIcon: "Export",
                renderSvgButton: true,
                disbaled: !((_b = chartData === null || chartData === void 0 ? void 0 : chartData.data) === null || _b === void 0 ? void 0 : _b.length),
            },
            {
                onClick: viewFinancialModule,
                priority: 6,
                title: ["Dashboard.viewFinancialSheet"],
                svgIcon: "FinancialSheet",
                btnClass: "viewFinancialSheet",
                renderSvgButton: true,
                isAllowed: isDashboard && isFinancial && !isNotification,
            },
        ];
        return (React.createElement(TableActions, { actionArray: btnList, menuItems: true, dropdownClassName: "dropdown-action-container trackedItemActions" }));
    };
    const actionButtons = (React.createElement("div", { style: { display: "inline-flex", alignItems: "center" } },
        React.createElement("div", { className: "filterDropdown" },
            React.createElement(LookupDropDown, { selectedValue: selectedFilterValue, onChange: (e) => onFilterChange(e), localizationKey: ["TrackedItem.filter"], lookUpType: LookupNames.LKChartDataFilter })),
        React.createElement("div", { hidden: selectedFilterValue !== ChartFilterOptions.Custom, className: "customRangePicker" },
            React.createElement(RangePicker, { allowClear: false, disabledDate: disabledDate, value: selectedRange.length > 0
                    ? [moment(selectedRange[0]), moment(selectedRange[1])]
                    : [null, null], onChange: (date, dateString) => {
                    onRangeChange(date, dateString);
                } })),
        buttonText && (React.createElement(Button, { type: "primary", onClick: () => {
                if (buttonText === "Overview") {
                    history.push(dashboardRoute);
                }
                else if (buttonText === "Watchlist") {
                    history.push(watchlistRoute);
                }
            } },
            buttonText === "Watchlist" && updateButtonText(),
            buttonText === "Overview" ? "Back To Overview" : "Back To Watchlist")),
        React.createElement(Button, { type: "default", onClick: () => (isSummary ? viewFinancialModule() : history.go(-1)), hidden: isNotification || hideBackButton }, "Back"),
        menuActionButton()));
    return (React.createElement("div", { "data-test": "action-button-section" }, actionButtons));
}
export default ChartActionButtons;
