import { COMPANY_VIEW, DASHBOARD_VIEWER, GROUP_CREATION, LOOKUP_VIEW, REPORT_DEFAULTS_VIEW, REPORT_VIEW, ROLE_VIEW, SITE_VIEW, USER_REPORTS, USER_VIEW, } from "./../../../core/constants/permissionConstants";
import { faBuilding, faChartBar, faHome, faTable, faUser, faUserTag, faBell, faFileAlt, faStar, } from "@fortawesome/free-solid-svg-icons";
import { DashboardType } from "../../../core/enums/dashboardType";
import localize from "../../../core/utils/translation-files";
import { treeMenuType } from "../../../core/enums/treeMenuType";
export const sidebarActions = {
    [DashboardType.Admin]: [
        {
            title: localize(["DashboardMenus.visionByDelphi"]),
            groupTitle: true,
            requiredPermissions: [DASHBOARD_VIEWER],
            order: 10,
        },
        {
            title: localize(["DashboardMenus.my", "DashboardMenus.watchList"]),
            routing: "watchlist",
            icon: faStar,
            svgIcon: "WatchlistMenu",
            requiredPermissions: [DASHBOARD_VIEWER],
            order: 15,
        },
        {
            title: localize(["DashboardMenus.overview"]),
            routing: "dashboard",
            icon: faHome,
            svgIcon: { active: "ActiveRoadmapMenu", inActive: "InActiveRoadmapMenu" },
            requiredPermissions: [DASHBOARD_VIEWER],
            order: 20,
        },
        {
            title: localize(["DashboardMenus.reports"]),
            routing: "reports",
            icon: faFileAlt,
            svgIcon: {
                active: "ActiveFinancialReportsMenu",
                inActive: "InActiveFinancialReportsMenu",
            },
            order: 30,
            requiredPermissions: [USER_REPORTS],
        },
        {
            title: localize(["DashboardMenus.notifications"]),
            adminTitle: localize(["DashboardMenus.notifications"]),
            routing: "notifications-events",
            svgIcon: {
                active: "ActiveNotificationsMenu",
                inActive: "InActiveNotificationsMenu",
            },
            order: 40,
            requiredPermissions: [],
        },
        {
            title: localize(["DashboardMenus.administration"]),
            groupTitle: true,
            requiredPermissions: [SITE_VIEW],
            order: 50,
        },
        {
            title: localize(["manage", "Group.groups"]),
            adminTitle: localize(["manage", "DashboardMenus.my", "Group.groups"]),
            routing: "groups",
            svgIcon: { active: "ActiveGroupMenu", inActive: "InActiveGroupMenu" },
            order: 60,
            requiredPermissions: [GROUP_CREATION],
            status: treeMenuType.collapse,
            isExpandableDefault: false,
            sub: [
                {
                    title: localize(["manage", "Company.companies"]),
                    adminTitle: localize([
                        "manage",
                        "DashboardMenus.my",
                        "Company.companies",
                    ]),
                    routing: "companies",
                    icon: faBuilding,
                    svgIcon: "CompanyMenu",
                    requiredPermissions: [COMPANY_VIEW],
                    status: treeMenuType.collapse,
                    isExpandableDefault: false,
                    sub: [
                        {
                            title: localize(["manage", "Site.sites"]),
                            adminTitle: localize([
                                "manage",
                                "DashboardMenus.my",
                                "Site.sites",
                            ]),
                            routing: "sites",
                            icon: faBuilding,
                            svgIcon: "SiteMenu",
                            requiredPermissions: [SITE_VIEW],
                        },
                    ],
                },
            ],
        },
        {
            title: localize(["manage", "User.users"]),
            adminTitle: localize(["manage", "DashboardMenus.my", "User.users"]),
            routing: "users",
            icon: faUser,
            svgIcon: "UserMenu",
            order: 70,
            requiredPermissions: [USER_VIEW],
        },
        {
            title: localize(["manage", "Role.roles"]),
            routing: "roles",
            icon: faUserTag,
            svgIcon: { active: "ActiveRoleMenu", inActive: "InActiveRoleMenu" },
            order: 80,
            requiredPermissions: [ROLE_VIEW],
        },
        {
            title: localize(["manage", "Lookup.lookups"]),
            routing: "lookups",
            icon: faTable,
            svgIcon: { active: "ActiveLookupMenu", inActive: "InActiveLookupMenu" },
            order: 110,
            requiredPermissions: [LOOKUP_VIEW],
        },
        {
            title: localize(["manage", "TrackedItem.trackedItems"]),
            adminTitle: localize([
                "manage",
                "DashboardMenus.my",
                "TrackedItem.trackedItems",
            ]),
            routing: "manage-client-tracked-items",
            requiredPermissions: [REPORT_VIEW],
            icon: faChartBar,
            svgIcon: {
                active: "ActiveTrackItemMenu",
                inActive: "InActiveTrackItemMenu",
            },
            order: 90,
        },
        {
            title: localize(["manage", "TrackedItem.defaults"]),
            adminTitle: localize(["manage", "TrackedItem.defaults"]),
            routing: "manage-tracked-item-defaults",
            requiredPermissions: [REPORT_DEFAULTS_VIEW],
            icon: faChartBar,
            svgIcon: {
                active: "ActiveDefaultMenu",
                inActive: "InActiveDefaultMenu",
            },
            order: 100,
        },
        {
            title: localize(["manage", "DashboardMenus.notifications"]),
            routing: "notifications",
            icon: faBell,
            svgIcon: {
                active: "NotificationSettingSvg",
                inActive: "NotificationSettingSvg",
            },
            order: 120,
            requiredPermissions: [SITE_VIEW],
        },
    ],
};
export const getSortedMenus = () => {
    const menus = sidebarActions[DashboardType.Admin];
    const sorted = menus.sort((a, b) => (a.order > b.order ? 1 : -1));
    return sorted;
};
