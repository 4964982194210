import { useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { useEffect } from "react";
import { SessionStorage } from "../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../core/constants/SessionVariables";
const UseCustomPagination = ({ totalRecords = 0, clientPagination = false, defaultPageSize = 0, customRowHeight = 0, stateParams = false, }) => {
    const { search, pathname, state } = useLocation();
    const parsedParams = stateParams
        ? state === null || state === void 0 ? void 0 : state.queryParams
        : queryString.parse(search ||
            SessionStorage.getKey(SessionVariables.QUERY_PARAMS, sessionStorage));
    const history = useHistory();
    const DEFAULT_CURRENT_PAGE = 1;
    const [recordCount, setRecordCount] = useState(totalRecords);
    const [currentPage, setCurrentPage] = useState((parsedParams === null || parsedParams === void 0 ? void 0 : parsedParams.page) ? Number(parsedParams === null || parsedParams === void 0 ? void 0 : parsedParams.page) : DEFAULT_CURRENT_PAGE);
    const [pageSize, setPageSize] = useState(0);
    useEffect(() => {
        if (defaultPageSize && !isNaN(defaultPageSize)) {
            setPageSize(defaultPageSize);
        }
    }, [defaultPageSize]);
    useEffect(() => {
        setRecordCount(totalRecords);
    }, [totalRecords]);
    useLayoutEffect(() => {
        const handlePerPageRecord = () => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (document.getElementsByTagName("tbody")[0] &&
                (customRowHeight ||
                    ((_a = document.getElementsByClassName("fixed-footer-wrapper")) === null || _a === void 0 ? void 0 : _a[0]))) {
                const paginationElement = (_b = document.getElementsByClassName("fixed-footer-wrapper")) === null || _b === void 0 ? void 0 : _b[0];
                const isDashboard = (_c = window.location.pathname) === null || _c === void 0 ? void 0 : _c.includes("dashboard");
                const table = document.getElementsByTagName("tbody")[0];
                const tableRow = ((_e = (_d = table.childNodes) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.clientHeight) > 0
                    ? (_f = table.childNodes) === null || _f === void 0 ? void 0 : _f[0]
                    : (_g = table.childNodes) === null || _g === void 0 ? void 0 : _g[1];
                let availableHeight = window.innerHeight -
                    (isDashboard
                        ? table.getBoundingClientRect().top
                        : tableRow === null || tableRow === void 0 ? void 0 : tableRow.getBoundingClientRect().top);
                availableHeight =
                    availableHeight -
                        (isDashboard ? paginationElement === null || paginationElement === void 0 ? void 0 : paginationElement.clientHeight : 20);
                const rowHeight = customRowHeight || (isDashboard ? 135 : 61); //tableRow.clientHeight; //140
                if (!rowHeight)
                    return;
                availableHeight =
                    availableHeight < rowHeight ? rowHeight : availableHeight;
                if (availableHeight < rowHeight)
                    availableHeight = rowHeight;
                let perPage = availableHeight / rowHeight;
                setPageSize((prev) => isFinite(perPage) ? parseInt(perPage.toString()) : prev);
            }
        };
        handlePerPageRecord();
        window.addEventListener("resize", (event) => {
            handlePerPageRecord();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientPagination && totalRecords, customRowHeight]);
    useEffect(() => {
        //on resize reset pages
        if (recordCount && pageSize) {
            const totalPages = Math.ceil(recordCount / pageSize);
            if (parseInt(currentPage.toString()) > totalPages) {
                if (clientPagination) {
                    setCurrentPage(totalPages);
                }
                else {
                    setCurrentPage(totalPages);
                    const updateParams = Object.assign({}, parsedParams);
                    updateParams.page = totalPages.toString();
                    handlePushParams(updateParams);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageSize, recordCount, clientPagination]);
    const handleCurrentPage = (selectedPage) => {
        setCurrentPage(selectedPage);
        if (!clientPagination) {
            const updatePageQuery = Object.assign({}, parsedParams);
            updatePageQuery.page = selectedPage;
            handlePushParams(updatePageQuery);
        }
    };
    const handleCustomTablePropChange = (extra) => {
        if (extra.action === "filter") {
            setCurrentPage(1);
            clientPagination && setRecordCount(extra.currentDataSource.length);
        }
    };
    const handlePushParams = (params) => {
        if (stateParams) {
            history.push(pathname, { queryParams: params });
            return;
        }
        history.push({ search: `?${queryString.stringify(params)}` });
    };
    return {
        currentPage,
        pageSize,
        totalRecords: recordCount,
        handleChangePage: handleCurrentPage,
        handleCustomTablePropChange,
    };
};
export default UseCustomPagination;
