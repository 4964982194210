import React from "react";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import CustomModal from "../../../../../../../layout/components/customModal";
import AddRemoveWatchListComponent from "./addRemoveWatchListComponent";
import "./styles.scss";
const AddRemoveWatchListModal = ({ handleToggleModalVisibility, selectedTrackItemId, visible = undefined, onAddRemoveWatchList, setTabsArray, }) => {
    return (React.createElement(CustomModal, { visible: visible === undefined ? !isEmpty(selectedTrackItemId) : visible, onCancel: handleToggleModalVisibility, maskClosable: true, destroyOnClose: true, footer: null, title: ["Add/Remove TrackedItem from Watchlist"], 
        // width="fit-content"
        modelClass: "AddRemoveWatchlistPopup" },
        React.createElement(AddRemoveWatchListComponent, { selectedTrackItemId: selectedTrackItemId, onAddRemoveWatchList: onAddRemoveWatchList, seTabsArrayParent: setTabsArray })));
};
export default AddRemoveWatchListModal;
