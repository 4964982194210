import CryptoJS from 'crypto-js';
const SECRET_KEY = CryptoJS.enc.Utf8.parse("CNEUNCCLREYUPSTQ");
const IV = CryptoJS.enc.Utf8.parse("1234567890123456");
// will be used to decrypt data recieved from backend, encoded through crypto
export const decryptData = (cipherText) => {
    if (!cipherText || !cipherText.length)
        return cipherText;
    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
};
