import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { WatchlistApi } from "../../../../../../core/api/watchlistApi";
import CustomModal from "../../../../../../layout/components/customModal";
import TableActions from "../../../../../../layout/components/customTable/tableActions";
import NewWatchListModalComponent from "../newWatchListModalComponent";
import "./styles.scss";
import WatchlistRenameComponent from "../watchlistRenameComponent/WatchlistRenameComponent";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import { browserHistoryMemoryContext } from "../../../../../../core/contexts/browserHistoryMemoryContext";
import AddRemoveWatchListModal from "../../../../shared/dashboard/DashboardRoadmap/components/addRemoveWatchList";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
const WatchlistTabs = ({ activeWatchlistTab, setActiveWatchListTab, setData, handleUpdateApiCall, controller, currentTab, }) => {
    var _a;
    const [modalVisible, setModalVisible] = useState(false);
    const [tabsArray, setTabsArray] = useState([]);
    const [addRemoveModal, setAddRemoveModal] = useState(undefined);
    const { location } = useHistory();
    const parseParams = queryString.parse((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.params);
    const trackItemId = parseParams === null || parseParams === void 0 ? void 0 : parseParams.trackedItemId;
    useEffect(() => {
        WatchlistApi.getUserWatchList(false).then((response) => {
            var _a;
            if (Array.isArray(response)) {
                setTabsArray(response);
                // Find the watchlist with isDefault: true
                const defaultWatchList = response.find((watchlist) => watchlist === null || watchlist === void 0 ? void 0 : watchlist.isDefault);
                if (defaultWatchList) {
                    currentTab.current = defaultWatchList === null || defaultWatchList === void 0 ? void 0 : defaultWatchList.id;
                    setActiveWatchListTab((_a = SessionStorage.getKey(SessionVariables.WATCHLIST_ACTIVE_TAB)) !== null && _a !== void 0 ? _a : defaultWatchList);
                }
                else {
                    currentTab.current = response[0].id;
                    setActiveWatchListTab(response[0]);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackItemId]);
    const hist = useHistory();
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const history = useMemo(() => {
        return browserHistory !== null && browserHistory !== void 0 ? browserHistory : hist;
    }, [browserHistory, hist]);
    const handleAddNewWatchList = (watchListName) => {
        handleToggleModalVisibility(undefined);
        setTabsArray((prev) => [...prev, watchListName]);
    };
    const handleDeleteWatchList = (event) => {
        const { id: activeWatchListId } = activeWatchlistTab;
        WatchlistApi.deleteWatchList(activeWatchListId).then((response) => {
            if (response === true) {
                const updateTabs = [...tabsArray].filter(({ id }) => id !== activeWatchListId);
                setTabsArray(updateTabs);
                setData(undefined);
                if ((updateTabs === null || updateTabs === void 0 ? void 0 : updateTabs.length) === 0) {
                    //clear data if last watchlist removed
                    setActiveWatchListTab(undefined);
                    SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, undefined);
                }
                else {
                    //on delete set previous as selected;
                    setActiveWatchListTab(updateTabs[updateTabs.length - 1]);
                    SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, updateTabs[updateTabs.length - 1]);
                }
            }
        });
    };
    const handleSetActiveTab = (activeKey) => {
        if (Number(activeKey) !== activeWatchlistTab.id) {
            setActiveWatchListTab(tabsArray.find(({ id }) => id === +activeKey));
            SessionStorage.setKey(SessionVariables.WATCHLIST_ACTIVE_TAB, tabsArray.find(({ id }) => id === +activeKey));
            setData(undefined); //clear listing data
        }
    };
    const handleToggleModalVisibility = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setModalVisible((prev) => !prev);
    };
    const [nameModalVisible, setNameModalVisible] = useState(false);
    const handleToggleNameModalVisibility = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setNameModalVisible((prev) => !prev);
    };
    const handleSetDefaultWatchList = () => {
        WatchlistApi.setDefaultWatchlist(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id).then((response) => {
            if (response) {
                // Update the tabsArray to set isDefault to true for the active watchlist
                setTabsArray((prevTabs) => prevTabs.map((tab) => {
                    if ((tab === null || tab === void 0 ? void 0 : tab.id) === (activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id)) {
                        return Object.assign(Object.assign({}, tab), { isDefault: true });
                    }
                    return Object.assign(Object.assign({}, tab), { isDefault: false });
                }));
            }
        });
    };
    const deleteBtn = [
        {
            priority: 1,
            onClick: handleToggleNameModalVisibility,
            title: ["rename"],
            svgIcon: "Edit",
            btnClass: "watchlistRenameBtn",
            iconStyle: { color: "#000000" },
            renderSvgButton: true,
            disbaled: !(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.canBeDeleted),
        },
        {
            priority: 2,
            onClick: handleDeleteWatchList,
            title: ["delete"],
            svgIcon: "Delete",
            iconStyle: { color: "#FF0000" },
            renderSvgButton: true,
            disbaled: !(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.canBeDeleted),
        },
        {
            priority: 3,
            onClick: handleSetDefaultWatchList,
            title: ["Set Default"],
            svgIcon: "DefaultWatchList",
            renderSvgButton: true,
            disbaled: tabsArray.some((tab) => tab.isDefault && tab.id === (activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id)),
        },
    ];
    return (React.createElement("div", { className: "row watchlist-tabs-container" },
        React.createElement(Tabs, { defaultActiveKey: String(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id), activeKey: String(activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id), onChange: handleSetActiveTab, key: "true" }, tabsArray.map(({ watchListName, id, isDefault }) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tabs.TabPane, { tab: React.createElement(React.Fragment, null,
                        watchListName,
                        isDefault && (React.createElement("span", { style: {
                                position: "absolute",
                                right: "0px",
                                display: "flex",
                            } },
                            React.createElement(RenderSvg, { icon: "AddedToWatchlist", style: {
                                    color: (activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id) === id && isDefault
                                        ? "#fff"
                                        : "#1B998F",
                                } })))), key: id }),
                ";"));
        })),
        React.createElement(Button, { type: "primary", onClick: handleToggleModalVisibility }, "Create New Watchlist"),
        React.createElement(TableActions, { actionArray: deleteBtn, wrapperClass: "dottedActionsButton watchlistActions watchlistDeleteBtn", dropdownClassName: "actionsDropdown wathclistDeleteDropdown", ShowActionText: false, menuItems: true }),
        React.createElement(WatchlistRenameComponent, { handleToggleNameModalVisibility: handleToggleNameModalVisibility, activeWatchlistTab: activeWatchlistTab, tabsArray: tabsArray, nameModalVisible: nameModalVisible, setActiveWatchListTab: setActiveWatchListTab, setTabsArray: setTabsArray }),
        React.createElement(CustomModal, { visible: modalVisible, onCancel: handleToggleModalVisibility, maskClosable: true, destroyOnClose: true, footer: null, title: ["Create Watchlist"], modelClass: "CreateWatchlistPopup" },
            React.createElement(NewWatchListModalComponent, { handleAddNewWatchList: handleAddNewWatchList, tabsArray: tabsArray })),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: () => {
                setAddRemoveModal(false);
                window.history.replaceState({}, "");
            }, selectedTrackItemId: trackItemId, visible: addRemoveModal, setTabsArray: setTabsArray, onAddRemoveWatchList: (_, _2, watchlistId) => {
                handleSetActiveTab(watchlistId.toString());
                (activeWatchlistTab === null || activeWatchlistTab === void 0 ? void 0 : activeWatchlistTab.id) === watchlistId && handleUpdateApiCall();
            } })));
};
export default WatchlistTabs;
