import { Button, Menu, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { InAppNotificationApi } from "../../../../../core/api/inAppNotificationApi";
import { notificationEventsRoute } from "../../../../../core/constants/routesPath";
import { getMessagesDateAndTimeStringGlobal } from "../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../core/helpers/isEmpty";
import localize from "../../../../../core/utils/translation-files";
import CustomModal from "../../../../../layout/components/customModal";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import { notifications } from "../../../../../layout/components/notifications";
import { useHistory } from "react-router-dom";
const RelatedNotificationsPopup = ({ itemId, visible, onClose, selectedItem, browserHistory, updateTreeNode, setTreeData, setListData, setSelectedItem, setOverallData, setDashboardListData, listKey, setNotificationCountListId, showFlag = true, }) => {
    const [data, setData] = useState(undefined);
    useEffect(() => {
        if (visible) {
            InAppNotificationApi.getRelatedNotifications(itemId, 0, {
                timeFilter: null,
                inAppStatus: null,
                flagged: false,
                fromDate: null,
                toDate: null,
                pageNo: 1,
                pageSize: 3,
                category: null,
                subject: null,
                sortByCreatedDate: null,
            }).then((res) => setData(res === null || res === void 0 ? void 0 : res.notificationsList));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, selectedItem]);
    useEffect(() => {
        setData([]);
    }, [visible]);
    const history = useHistory();
    const handleViewAllRecentNotifications = () => {
        history.push(notificationEventsRoute, {
            isRoadmapItem: true,
            selectedItemId: itemId,
            isAllNotification: false,
            isAllNotificationId: null,
        });
    };
    const flagNotificationHandler = (id) => {
        // const temp = [...data].filter((el) => el.id !== id);
        // setData(temp);
        if (selectedItem) {
            const updatedItem = Object.assign(Object.assign({}, selectedItem), { notificationCount: selectedItem.notificationCount - 1 });
            setSelectedItem(updatedItem);
            if (setTreeData) {
                // setTreeData((origin) =>
                //   updateTreeNode(updatedItem, selectedItem?.children, origin)
                // );
                setNotificationCountListId((prev) => {
                    return Object.assign(Object.assign({}, prev), { [itemId]: prev[itemId] - 1 });
                });
            }
            if (setListData) {
                setListData((origin) => {
                    let updateData = Array.isArray(origin) ? origin : origin === null || origin === void 0 ? void 0 : origin.dataList;
                    updateData = updateData === null || updateData === void 0 ? void 0 : updateData.map((el) => el.itemId === itemId ? updatedItem : el);
                    return Array.isArray(origin)
                        ? updateData
                        : Object.assign(Object.assign({}, origin), { dataList: updateData });
                });
                //  onClose();
            }
            if (setOverallData) {
                setOverallData((origin) => {
                    return listKey
                        ? Object.assign(Object.assign({}, origin), { [listKey]: updatedItem }) : updatedItem;
                });
            }
            if (setDashboardListData) {
                setDashboardListData((origin) => {
                    return Object.assign(Object.assign({}, origin), { [listKey]: [...origin[listKey]].map((el) => el.itemId !== itemId ? el : updatedItem) });
                });
            }
        }
        InAppNotificationApi.changeNotificationFlag(id, true).then((response) => {
            if (response) {
                if (!isEmpty(response.Message)) {
                    notifications.success(response.Message);
                }
                if (response.status === 200) {
                    InAppNotificationApi.getRelatedNotifications(itemId, 0, {
                        timeFilter: null,
                        inAppStatus: null,
                        flagged: false,
                        fromDate: null,
                        toDate: null,
                        pageNo: 1,
                        pageSize: 3,
                        category: null,
                        subject: null,
                        sortByCreatedDate: null,
                    }).then((res) => setData(res === null || res === void 0 ? void 0 : res.notificationsList));
                }
            }
        });
    };
    return (React.createElement("div", null,
        React.createElement(CustomModal, { visible: visible, onCancel: onClose, maskClosable: false, destroyOnClose: true, footer: null, title: ["Dashboard.notifications"], width: "50%" },
            React.createElement(Menu, { className: "action-menu related-notifications-list col-12" },
                (data === null || data === void 0 ? void 0 : data.length) ? (data.map((item, index) => (React.createElement(Menu.Item, { className: "action-item d-flex w-100", key: index, style: {
                        height: "auto",
                        cursor: "text",
                    } },
                    showFlag && (React.createElement(Tooltip, { placement: "top", title: localize([
                            "Notification.flag",
                            "Notification.thisNotification",
                        ]), key: index },
                        React.createElement("span", { className: "relatedNotificationsIcon" },
                            React.createElement(RenderSvg, { icon: "RoadMapFlag", style: { color: "#EA5455" }, onClick: (e) => flagNotificationHandler(item.id) })))),
                    React.createElement("span", { className: "text" },
                        React.createElement("span", { className: "sub-text" },
                            React.createElement("span", null, getMessagesDateAndTimeStringGlobal(item === null || item === void 0 ? void 0 : item.createdDate))),
                        React.createElement("span", { className: "message" }, item.subject)))))) : (React.createElement("span", { className: "empty-item" }, "No Notifications")),
                (data === null || data === void 0 ? void 0 : data.length) ? (React.createElement("div", { className: "dropdown-actions" },
                    React.createElement(Button, { type: "primary", className: "w-100", onClick: handleViewAllRecentNotifications }, localize(["Notification.viewMoreNotifications"])))) : ("")))));
};
export default RelatedNotificationsPopup;
