import { Tooltip } from "antd";
import React from "react";
import { formatDate } from "../../../../../../core/helpers/dateFormatter";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import movingAveragePng from "../../../../../../assets/img/movingAverage.png";
import actualValuePng from "../../../../../../assets/img/actualValue.png";
import "./styles.scss";
import { formatValueByConf } from "../../../../../../core/helpers/chartYAxisLabel";
import localize from "../../../../../../core/utils/translation-files";
const RatingColumnComponent = ({ bandDateTime, status, statusMA, movingAverage, statusRgbColor, systemCreated, valueType, decimalPlaces, column = "", projectionRequired, isFinancial, weight, weightFlag, displayValue = null, actualValue = null, tooltipText = " ", }) => {
    if (column && column === "predicted" && !projectionRequired) {
        return (React.createElement(Tooltip, { title: "No rating available due to Projection Required configurations", placement: "topRight" },
            React.createElement("span", { className: "bold-text NoDataCol" }, "No Rating")));
    }
    if (isEmpty(status)) {
        return (React.createElement("div", null,
            React.createElement(Tooltip, { title: column === "predicted"
                    ? localize(["TrackedItem.cannotIdentifyTrend"])
                    : localize(["TrackedItem.ratingIsStable"]), placement: "top" },
                React.createElement("div", { className: "bold-text NoDataCol" }, column === "predicted"
                    ? localize(["TrackedItem.noPrediction"])
                    : localize(["TrackedItem.stableRating"])))));
    }
    return (
    // <div className="rating-container">
    //   <div>{moment(bandDateTime).format(defaultDateFormat)}</div>
    //   <div>{status}</div>
    //   <div style={{ backgroundColor: statusRgbColor }}>
    //     {statusMA ? `${finalValue(statusMA, valueType)}` : "-"}
    //   </div>
    // </div>
    React.createElement(Tooltip, { title: tooltipText },
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("div", { className: "rating-container" },
                React.createElement("div", null, bandDateTime
                    ? `${formatDate(bandDateTime)
                        .split("-")
                        .join("/")
                        .slice(0, 6)}${formatDate(bandDateTime)
                        .split("-")
                        .join("/")
                        .slice(-2)}`
                    : "N/A"),
                React.createElement(Tooltip, { title: weight !== 0
                        ? ""
                        : "This item doesn't contribute to the overall rating" },
                    React.createElement("div", null, status && weight !== 0
                        ? // && !weightFlag
                            status === null || status === void 0 ? void 0 : status.slice(-1)
                        : "-")),
                React.createElement("div", { style: !isEmpty(statusMA) && statusRgbColor
                        ? {
                            backgroundColor: statusRgbColor,
                        }
                        : { color: "black" } }, !isEmpty(statusMA)
                    ? weight !== 0
                        ? // && !weightFlag
                            `${Math.trunc(statusMA)}%`
                        : displayValue === 0 &&
                            displayValue != null &&
                            actualValue != null
                            ? `${formatValueByConf(actualValue, valueType, decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 2)}`
                            : `${formatValueByConf(movingAverage, valueType, decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 2)}`
                    : "-")),
            React.createElement(Tooltip, { title: "Actual Value" }, !(weight !== 0 && !weightFlag) &&
                displayValue != null &&
                displayValue === 0 &&
                systemCreated !== null &&
                systemCreated === false &&
                actualValue != null && (React.createElement("div", null,
                " ",
                React.createElement("img", { src: actualValuePng, alt: "movingAveragePng", style: { width: "22px", height: "22px", marginTop: "8px" } })))),
            React.createElement(Tooltip, { title: "Moving Average" }, !(weight !== 0 && !weightFlag) &&
                displayValue != null &&
                systemCreated !== null &&
                systemCreated === false &&
                displayValue === 1 && (React.createElement("div", null,
                " ",
                React.createElement("img", { src: movingAveragePng, alt: "actualValuePng", style: { width: "22px", height: "22px", marginTop: "8px" } })))),
            ((weight !== 0 &&
                // && !weightFlag
                column !== "predicted") ||
                systemCreated === true) && (React.createElement("div", null,
                " ",
                React.createElement("img", { src: null, alt: "", style: {
                        width: "22px",
                        height: "22px",
                        marginTop: "8px",
                        opacity: 0,
                    } }))))));
};
export default RatingColumnComponent;
