import { Space } from "antd";
import React, { useMemo } from "react";
import isEmpty from "../../../core/helpers/isEmpty";
import usePermissions from "../../../core/hooks/usePermissions";
import { getOptionsById } from "../../../core/utils/arrayUtils";
import "./style.scss";
const SelectedListingFilters = ({ filters, hidden = false, }) => {
    const { groupOptions } = usePermissions();
    const filterOptionsName = useMemo(() => {
        var _a, _b;
        let optionsNameList = (_a = Object.values(filters)) === null || _a === void 0 ? void 0 : _a.filter((value) => !isEmpty(value)).reduce((prev, curr, index, arr) => {
            var _a;
            const params = [...arr.slice(0, index + 1)];
            const optionName = (_a = getOptionsById(groupOptions, ...params)) === null || _a === void 0 ? void 0 : _a.name;
            if (optionName && !prev.includes(optionName)) {
                return [...prev, optionName];
            }
            return prev;
        }, []);
        return (_b = optionsNameList) === null || _b === void 0 ? void 0 : _b.filter(Boolean);
    }, [filters, groupOptions]);
    if (!(filterOptionsName === null || filterOptionsName === void 0 ? void 0 : filterOptionsName.length))
        return React.createElement(React.Fragment, null);
    return (React.createElement(Space, { className: "selected_filter", hidden: hidden },
        React.createElement("span", { className: "filter_text" }, "Filter By "),
        filterOptionsName.map((value) => (React.createElement("div", { className: "selected_filter_name" }, value)))));
};
export default SelectedListingFilters;
