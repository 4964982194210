var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import WatchlistContainer from "../watchlistContainer";
import "./styles.scss";
import CategoryColumnComponent from "../categoryColumnComponent";
import RatingColumnComponent from "../ratingColumnComponent";
import NotificationColumnComponent from "../notificationColumnComponent";
import { Table } from "antd";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../,./../../../../core/constants/SessionVariables";
import usePermissions from "../../../../../../core/hooks/usePermissions";
import { usePromiseTracker } from "react-promise-tracker";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { TrackedItemsVariables } from "../../../../../../core/constants/TrackedItemsConstants";
const MultiWatchListExpandedTable = (_a) => {
    var _b;
    var { selectedSortValue, isPrintMode = false, data, openNotificationModal, notificationCount, setSorting, sorting, isTableSort, navigateToDashboard, itemId } = _a, props = __rest(_a, ["selectedSortValue", "isPrintMode", "data", "openNotificationModal", "notificationCount", "setSorting", "sorting", "isTableSort", "navigateToDashboard", "itemId"]);
    const { isDelphieorSuperUser, isGroupAdmin, isCompanyAdmin, isSingleCompanyOrSiteAdmin, isSingleSiteAdmin, } = usePermissions();
    const values = {
        itemName: "name",
        companyName: "company",
        siteName: "site",
        groupName: "group",
        previousRatingInfoDTO: "prevRating",
        predictedBandInfoDTO: "predictedRating",
        ascend: "asc",
        descend: "desc",
    };
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const applySorting = (sorter) => {
        const sortColumnName = values[sorter === null || sorter === void 0 ? void 0 : sorter.columnKey];
        const sortOrder = values[sorter === null || sorter === void 0 ? void 0 : sorter.order];
        isTableSort.current =
            sortColumnName === undefined || sortOrder === undefined ? false : true;
        sortColumnName === undefined || sortOrder === undefined
            ? setSorting(null)
            : setSorting({ sortColumnName, sortOrder });
        return null;
    };
    const navigateToWatchlistDashboard = (record) => {
        var _a;
        SessionStorage.setKey(SessionVariables.WATCHLIST_TAB, (_a = props.activeWatchListTab) === null || _a === void 0 ? void 0 : _a.id, sessionStorage);
        SessionStorage.setKey(SessionVariables.WATCHLIST_ITEM_ID, record === null || record === void 0 ? void 0 : record.itemId, 
        // expandedRowKeys[0],
        sessionStorage);
        SessionStorage.setKey(SessionVariables.WATCHLIST_ITEM_LEVEL, record === null || record === void 0 ? void 0 : record.itemName, sessionStorage);
        SessionStorage.setKey(SessionVariables.BUTTON_TEXT, "Watchlist", sessionStorage);
        navigateToDashboard(+(record === null || record === void 0 ? void 0 : record.itemId), record === null || record === void 0 ? void 0 : record.trackedItemCompanyId);
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "itemName",
            key: "itemName",
            ellipsis: true,
            sorter: true,
            //width: 120,
            render: (itemName, row) => (React.createElement("span", { id: itemId && (row === null || row === void 0 ? void 0 : row.itemId) === itemId && "expandedRowId", style: { cursor: "pointer" }, onClick: () => {
                    navigateToWatchlistDashboard(row);
                } }, itemName)),
        },
        {
            title: "Group",
            dataIndex: "groupName",
            key: "groupName",
            align: "center",
            width: isDelphieorSuperUser ? "" : 0,
            sorter: true,
            render: (groupName, { groupRating, valueType, decimalPlaces }) => isDelphieorSuperUser && (React.createElement(CategoryColumnComponent, { companyName: groupName, ratingCardProps: {
                    bandDateTime: groupRating === null || groupRating === void 0 ? void 0 : groupRating.bandDateTime,
                    status: groupRating === null || groupRating === void 0 ? void 0 : groupRating.currentStatus,
                    statusRgbColor: groupRating === null || groupRating === void 0 ? void 0 : groupRating.currentStatusRgb,
                    statusMA: groupRating === null || groupRating === void 0 ? void 0 : groupRating.currentStatusMovingAverage,
                    decimalPlaces,
                }, valueType: valueType })),
        },
        {
            title: "Company",
            dataIndex: "companyName",
            key: "companyName",
            align: "center",
            width: isDelphieorSuperUser || isGroupAdmin || !isSingleCompanyOrSiteAdmin
                ? ""
                : 0,
            sorter: true,
            render: (companyName, { companyRating, valueType, decimalPlaces }) => (isDelphieorSuperUser ||
                isGroupAdmin ||
                !isSingleCompanyOrSiteAdmin) && (React.createElement(CategoryColumnComponent, { companyName: companyName, ratingCardProps: {
                    bandDateTime: companyRating === null || companyRating === void 0 ? void 0 : companyRating.bandDateTime,
                    status: companyRating === null || companyRating === void 0 ? void 0 : companyRating.currentStatus,
                    statusRgbColor: companyRating === null || companyRating === void 0 ? void 0 : companyRating.currentStatusRgb,
                    statusMA: companyRating === null || companyRating === void 0 ? void 0 : companyRating.currentStatusMovingAverage,
                    decimalPlaces,
                }, valueType: valueType })),
        },
        {
            title: "Site",
            dataIndex: "siteName",
            key: "siteName",
            align: "center",
            width: isDelphieorSuperUser ||
                isGroupAdmin ||
                isCompanyAdmin ||
                !isSingleSiteAdmin
                ? ""
                : 0,
            sorter: true,
            render: (siteName, { siteRating, valueType, decimalPlaces }) => (isDelphieorSuperUser ||
                isGroupAdmin ||
                isCompanyAdmin ||
                !isSingleSiteAdmin) && (React.createElement(CategoryColumnComponent, { companyName: siteName, ratingCardProps: {
                    bandDateTime: siteRating === null || siteRating === void 0 ? void 0 : siteRating.bandDateTime,
                    status: siteRating === null || siteRating === void 0 ? void 0 : siteRating.currentStatus,
                    statusRgbColor: siteRating === null || siteRating === void 0 ? void 0 : siteRating.currentStatusRgb,
                    statusMA: siteRating === null || siteRating === void 0 ? void 0 : siteRating.currentStatusMovingAverage,
                    decimalPlaces,
                }, valueType: valueType })),
        },
        {
            title: "Previous Rating & value",
            dataIndex: "previousRatingInfoDTO",
            key: "previousRatingInfoDTO",
            width: 220,
            align: "center",
            sorter: true,
            render: (value, { valueType, decimalPlaces, categoryId, weight, weightFlag, displayValue, systemCreated, }) => (React.createElement(React.Fragment, null,
                React.createElement(RatingColumnComponent, { tooltipText: "Previous Rating & value", valueType: valueType, bandDateTime: value === null || value === void 0 ? void 0 : value.previousBandDateTime, status: (value === null || value === void 0 ? void 0 : value.previousStatusMovingAverage) !== null
                        ? value === null || value === void 0 ? void 0 : value.previousStatus
                        : null, statusRgbColor: value === null || value === void 0 ? void 0 : value.previousStatusRgb, statusMA: value === null || value === void 0 ? void 0 : value.previousBandPercentage, decimalPlaces: decimalPlaces, isFinancial: categoryId === TrackedItemsVariables.FinancialCategoryCode, movingAverage: value === null || value === void 0 ? void 0 : value.previousStatusMovingAverage, weight: weight, weightFlag: weightFlag, actualValue: value === null || value === void 0 ? void 0 : value.previousActualValue, displayValue: displayValue, systemCreated: systemCreated }))),
        },
        {
            title: "Current Rating & value",
            dataIndex: "currentRatingInfoDTO",
            key: "currentRatingInfoDTO",
            width: 220,
            align: "center",
            render: (value, { valueType, decimalPlaces, categoryId, weight, weightFlag, displayValue, systemCreated, }) => (React.createElement(RatingColumnComponent, { tooltipText: "Current Rating & value", valueType: valueType, bandDateTime: value === null || value === void 0 ? void 0 : value.bandDateTime, status: value === null || value === void 0 ? void 0 : value.currentStatus, statusRgbColor: value === null || value === void 0 ? void 0 : value.currentStatusRgb, statusMA: value === null || value === void 0 ? void 0 : value.currentBandPercentage, decimalPlaces: decimalPlaces, isFinancial: categoryId === TrackedItemsVariables.FinancialCategoryCode, movingAverage: value === null || value === void 0 ? void 0 : value.currentStatusMovingAverage, weight: weight, weightFlag: weightFlag, actualValue: value === null || value === void 0 ? void 0 : value.currentActualValue, displayValue: displayValue, systemCreated: systemCreated })),
        },
        {
            title: "Prediction Rating & value",
            dataIndex: "predictedBandInfoDTO",
            key: "predictedBandInfoDTO",
            width: 220,
            align: "center",
            sorter: true,
            render: (value, { valueType, decimalPlaces, categoryId, weight, weightFlag }) => (React.createElement(React.Fragment, null,
                React.createElement(RatingColumnComponent, { column: "predicted", tooltipText: "Prediction Rating & value", valueType: valueType, bandDateTime: value === null || value === void 0 ? void 0 : value.predictedBandDateTime, status: value === null || value === void 0 ? void 0 : value.predictedRating, statusRgbColor: value === null || value === void 0 ? void 0 : value.rgbColor, statusMA: value === null || value === void 0 ? void 0 : value.predictedBandPercentage, decimalPlaces: decimalPlaces, projectionRequired: value === null || value === void 0 ? void 0 : value.projectionRequired, isFinancial: categoryId === TrackedItemsVariables.FinancialCategoryCode, movingAverage: value === null || value === void 0 ? void 0 : value.predictedValue, weight: weight, weightFlag: weightFlag }))),
        },
        // {
        //   title: "Prediction Rating & value",
        //   dataIndex: "address",
        //   key: "address",
        //   align: "center",
        //   width: 190,
        //   render: (value) => <RatingColumnComponent />,
        // },
        {
            title: "Notifications",
            dataIndex: "notificationCount",
            key: "notificationCount",
            align: "center",
            width: 100,
            className: "notification-column",
            render: (value, item, index) => (React.createElement(NotificationColumnComponent, { count: notificationCount[item === null || item === void 0 ? void 0 : item.itemId], item: item, onClick: openNotificationModal })),
        },
    ];
    useEffect(() => {
        var _a;
        if (!isEmpty((_a = props.activeWatchListTab) === null || _a === void 0 ? void 0 : _a.id)) {
            setExpandedRowKeys([]); //reset expanded key on changing
        }
        var keys = [];
        if (itemId) {
            keys.push(itemId);
        }
        setExpandedRowKeys(keys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_b = props.activeWatchListTab) === null || _b === void 0 ? void 0 : _b.id]);
    const getRecordKey = (record) => (record === null || record === void 0 ? void 0 : record.id) || Object.values(record)[0];
    const onTableRowExpand = (expanded, record) => {
        var keys = [];
        if (expanded) {
            keys.push(getRecordKey(record)); // I have set my record.id as row key. Check the documentation for more details.
        }
        setExpandedRowKeys(keys);
    };
    const { promiseInProgress } = usePromiseTracker({ area: "LoadMore" });
    return (React.createElement("div", null,
        React.createElement("div", { className: `watchlistLoading align-content-center justify-content-center row
          `, hidden: !promiseInProgress },
            React.createElement(RenderSvg, { icon: "Loader" })),
        React.createElement(Table, { locale: {
                triggerDesc: "Click to sort alphabetically",
                triggerAsc: "Click to sort alphabetically",
                cancelSort: "Click to cancel sorting",
            }, onChange: (pagination, filters, sorter) => applySorting(sorter), className: `${isPrintMode ? "loadDataClass" : ""} multi-watch-list-table-wrapper`, columns: columns, rowKey: (record) => getRecordKey(record), rowClassName: (record, index) => {
                return expandedRowKeys[0] === getRecordKey(record)
                    ? `expanded-row`
                    : ``;
            }, pagination: false, scroll: isPrintMode
                ? {
                    y: "initial",
                }
                : {
                    y: `calc(100vh - 25.5em)`,
                }, expandable: {
                onExpand: onTableRowExpand,
                expandedRowKeys: expandedRowKeys,
                expandedRowRender: (record) => (React.createElement(WatchlistContainer, Object.assign({ isPrintMode: isPrintMode, expandedRowParentRecord: record, allData: data }, props, { parentDataLength: data === null || data === void 0 ? void 0 : data.length, navigateToWatchlistDashboard: navigateToWatchlistDashboard }))),
                expandIcon: ({ expanded, onExpand, record }) => expanded ? (React.createElement(RenderSvg, { icon: "CollapseTable", onClick: (e) => onExpand(record, e), style: { color: "#398F83" } })) : (React.createElement(RenderSvg, { icon: "ExpandTable", onClick: (e) => onExpand(record, e) })),
                //rowExpandable: (record) => record.name !== "Not Expandable",
            }, dataSource: data })));
};
export default MultiWatchListExpandedTable;
