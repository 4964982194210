var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from "antd";
import React, { useState } from "react";
import Header from "../../../../../layout/components/common/header";
import CompanyTable from "./components/companyTable";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCompanies, activateCompany, inActivateCompany, } from "../../../../../redux/company/actions";
import { companyChanged, setContextCompany, } from "../../../../../redux/context/actions";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { COMPANY_CREATION, COMPANY_EDIT, } from "../../../../../core/constants/permissionConstants";
import { addCompanyRoute, editGroupRoute, } from "../../../../../core/constants/routesPath";
import { notifications } from "../../../../../layout/components/notifications";
import localize from "../../../../../core/utils/translation-files";
import { DelphiCompanyId } from "../../../../../core/constants/companyConstants";
import useDidMount from "../../../../../core/hooks/useDidMount";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import TableActions from "../../../../../layout/components/customTable/tableActions";
import isActionAllowed from "../../../../../core/authorization/userPermissions";
import usePermissions from "../../../../../core/hooks/usePermissions";
import { EyeOutlined } from "@ant-design/icons";
import RenderSvg from "../../../../../layout/components/icons/RenderSvg";
import PrintButton from "../../../../../layout/components/common/PrintButton";
import UseCustomPagination from "../../../../../layout/components/hooks/useCustomPagination";
import ListingSearch from "../../../../../layout/components/listingSearch";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
const CompanyListingContainer = ({ companies, getCompanies, activateCompany, inActivateCompany, setContextCompany, companyChanged, isPrintMode, }) => {
    var _a;
    const history = useHistory();
    const { handlePrint, nodeRef } = UsePrintPage();
    // storing company listing data into session variable for accessing it on site listing 
    SessionStorage.setKey(SessionVariables.COMPANIES_LISTING_DATA, companies);
    const { isSingleGroup, singleGroupValues } = usePermissions();
    const [groupId] = useState(singleGroupValues === null || singleGroupValues === void 0 ? void 0 : singleGroupValues.id);
    const _b = UseCustomPagination({
        totalRecords: (_a = companies === null || companies === void 0 ? void 0 : companies.length) !== null && _a !== void 0 ? _a : 0,
        clientPagination: true,
    }), { handleCustomTablePropChange, pageSize } = _b, restPaginationProps = __rest(_b, ["handleCustomTablePropChange", "pageSize"]);
    useDidMount(() => {
        if (companies.length === 0) {
            getCompanies().catch((error) => {
                throw error;
            });
        }
    });
    const openEditModal = (companies, isReadOnly) => {
        history.push(`companies/edit/${companies.id}`, {
            companyId: companies.id,
            isReadOnly,
        });
    };
    const activateCompanyHandler = (data) => {
        activateCompany(data);
        notifications.success(localize(["Company.company", "enabled", "successfully"]));
    };
    const inActivateCompanyHandler = (data) => {
        inActivateCompany(data);
        notifications.success(localize(["Company.company", "disabled", "successfully"]));
    };
    const companiesActions = (company) => {
        const actionArray = [
            {
                priority: 2,
                title: ["edit", "Company.company"],
                onClick: (event) => {
                    event.stopPropagation();
                    openEditModal(company, false);
                },
                svgIcon: "Edit",
                isAllowed: isActionAllowed([COMPANY_EDIT], company),
            },
            {
                priority: 2,
                title: ["view", "Company.company"],
                onClick: (event) => {
                    event.stopPropagation();
                    openEditModal(company, true);
                },
                svgIcon: "View",
                isAllowed: !isActionAllowed([COMPANY_EDIT], company),
            },
            {
                priority: 1,
                title: company.active
                    ? ["Company.archive", "Company.company"]
                    : ["Company.unarchive", "Company.company"],
                isModal: true,
                modalTitle: [
                    "areYouSureTo",
                    `${company.active ? "Archive" : "unarchive"}`,
                    "Company.thisCompany?",
                ],
                onConfirm: (event) => {
                    event.stopPropagation();
                    company.active
                        ? inActivateCompanyHandler(company.id)
                        : activateCompanyHandler(company.id);
                },
                onCancel: (event) => {
                    event.stopPropagation();
                },
                isAllowed: isActionAllowed([COMPANY_EDIT], company),
                children: (React.createElement(Switch, { className: `ml-1 ${company.id === DelphiCompanyId ? `disableSwitch` : ``} `, checked: company.active, onChange: (checked, event) => event.stopPropagation() })),
            },
        ];
        return React.createElement(TableActions, { actionArray: actionArray });
    };
    return (React.createElement("div", { ref: nodeRef },
        React.createElement(Header, { rightComponent: React.createElement("div", { className: "row" },
                React.createElement(ListingSearch, null),
                React.createElement(PrintButton, { printOnClick: handlePrint }),
                React.createElement(Button, { type: "primary", onClick: () => history.push(`${editGroupRoute}${groupId}`, {
                        groupId,
                        isReadOnly: true,
                    }), icon: React.createElement(EyeOutlined, { style: { fontSize: "16px" } }), hidden: !isSingleGroup },
                    React.createElement("span", { className: "custom-span" }, localize(["view", "Group.group"]))),
                React.createElement(Button, { type: "primary", onClick: () => history.push(addCompanyRoute), hidden: !isActionAllowed(COMPANY_CREATION) },
                    React.createElement(RenderSvg, { icon: "AddCompany" }),
                    localize(["add", "Company.company"]))) }),
        React.createElement(CompanyTable, { data: companies, actions: companiesActions, companyChanged: companyChanged, setContextCompany: setContextCompany, currentPage: restPaginationProps.currentPage, pageSize: pageSize, handleCustomTablePropChange: handleCustomTablePropChange, paginationSection: Object.assign({ pageSize }, restPaginationProps), isPrintMode: isPrintMode })));
};
function mapStateTopProps(state) {
    var _a;
    return {
        companies: state.companies,
        isPrintMode: (_a = state.togglePrint) === null || _a === void 0 ? void 0 : _a.status,
    };
}
const mapDispatchToProps = {
    getCompanies,
    activateCompany,
    inActivateCompany,
    setContextCompany,
    companyChanged,
};
CompanyListingContainer.propTypes = {
    companies: PropTypes.array.isRequired,
    getCompanies: PropTypes.func.isRequired,
    activateCompany: PropTypes.func.isRequired,
    inActivateCompany: PropTypes.func.isRequired,
    companyChanged: PropTypes.func.isRequired,
    setContextCompany: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(CompanyListingContainer);
