import React from "react";
import CustomTable from "../../../../../../../layout/components/customTable";
import ActionsContainer from "../../../../../admin/watchlist/components/watchlistTable/components/ActionsContainer";
import WatchlistPredictionChart from "../../../../../../../layout/components/rechart/tiny-chart/WatchlistPredictionChart";
import HistoricalChart from "../../../../../../../layout/components/rechart/tiny-chart/HistoricalChart";
import WatchlistRatingColumnComponent from "../../../../../admin/watchlist/components/watchlistTable/components/WatchlistRatingColumnComponent";
import WatchlistTitleContainer from "../../../../../admin/watchlist/components/watchlistTable/components/WatchlistTitleContainer";
import DashboardActionButtons from "../../../components/dashboardActions";
import { defaultDashboardScrollPosition } from "../../../../../../../core/constants/tableScrollConstants";
import { usePromiseTracker } from "react-promise-tracker";
import RenderSvg from "../../../../../../../layout/components/icons/RenderSvg";
function UniversalRatingTable({ data, handleRowClick, scaleBandArray, customTablePropChange = null, selectedFrequency = null, openNotificationModal = null, handleToggleWatchListModal = null, userId = null, }) {
    const columns = [
        {
            fieldName: "itemName",
            title: ["TrackedItem.trackedItem"],
            className: "w-10 watchlistTitleColum watchlistTrackItemListing ",
            width: 20,
            searchable: true,
            sortable: true,
            render: (row, data) => (React.createElement(React.Fragment, null,
                React.createElement(WatchlistTitleContainer, { row: row, data: data }),
                React.createElement(WatchlistRatingColumnComponent, { row: row, data: data, cardText: "Tracked Item Value", showAllCards: true }))),
        },
        {
            fieldName: "historyChartDTOList",
            title: ["Dashboard.historicalPerformance"],
            className: "w-30 watchlistChartColum",
            width: 27,
            align: "center",
            render: (row, data) => (React.createElement(React.Fragment, null,
                React.createElement(HistoricalChart, { data: data, row: row, frequency: +(selectedFrequency === null || selectedFrequency === void 0 ? void 0 : selectedFrequency.code), isDashboard: true, weight: data === null || data === void 0 ? void 0 : data.weight, weightFlag: data === null || data === void 0 ? void 0 : data.weightFlag }))),
        },
        {
            fieldName: "predictedBandInfoDTO",
            title: (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "bold-content" }, "RodaBI"),
                "Prediction")),
            className: "w-30 watchlistPredictionColum",
            width: 26,
            align: "center",
            render: (data, row) => (React.createElement(WatchlistPredictionChart, { data: data, row: row, weight: row === null || row === void 0 ? void 0 : row.weight, weightFlag: row === null || row === void 0 ? void 0 : row.weightFlag })),
        },
        {
            fieldName: "notificationCount",
            title: ["Dashboard.criticalNotifications"],
            className: "w-15 watchlistActionColum",
            width: 12,
            align: "center",
            render: (row, data) => (React.createElement(ActionsContainer, { row: row, data: data, openMessageModal: openNotificationModal })),
        },
    ];
    const actionColumn = {
        fieldName: "actions",
        title: ["actions"],
        className: "w-5",
        width: 7,
        align: "center",
        render: (actions, row) => (React.createElement(React.Fragment, null, DashboardActionButtons(row, handleToggleWatchListModal, userId, "universalTabList"))),
    };
    const displayedColumns = [...columns, actionColumn];
    const { promiseInProgress } = usePromiseTracker({ area: "LoadMore" });
    return (React.createElement("div", { className: "customListing watchlistTable", "data-test": "watchlist-table" },
        React.createElement("div", { className: "watchlistLoading align-content-center justify-content-center row", hidden: !promiseInProgress },
            React.createElement(RenderSvg, { icon: "Loader" })),
        React.createElement(CustomTable, { data: data, columns: displayedColumns, dataType: "any", scroll: true, scrollPosition: defaultDashboardScrollPosition, clientPagination: false, serverSearching: true, serverSorting: true, customTablePropChange: customTablePropChange, searchAllColumn: false, rowClickHandler: handleRowClick })));
}
export default UniversalRatingTable;
