import React from "react";
import { Button, Input, Tooltip } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import jwt from "jwt-decode";
import { passwordRegex } from "../../../../core/utils/regex";
import PropTypes from "prop-types";
import localize from "../../../../core/utils/translation-files";
import { SessionStorage } from "../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../core/constants/SessionVariables";
import { inputDefaultLength } from "../../../../core/utils/default-length";
function ResetPasswordForm({ modalTitle, modalDescription, handleChangePassword, hideOldPassword, }) {
    let LoginSchema = null;
    let intialState = {
        newPassword: "",
        confirmPassword: "",
        oldPassword: "",
    };
    if (hideOldPassword) {
        LoginSchema = Yup.object({
            newPassword: Yup.string()
                .required(localize(["Reset.new", "password", "isRequired!"]))
                .matches(passwordRegex.regex, passwordRegex.message)
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            confirmPassword: Yup.string()
                .required(localize(["confirm", "password", "isRequired!"]))
                .oneOf([Yup.ref("newPassword"), null], localize(["passwordsMustMatch"]))
                .matches(passwordRegex.regex, passwordRegex.message)
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
        });
    }
    else {
        LoginSchema = Yup.object({
            newPassword: Yup.string()
                .required(localize(["Reset.new", "password", "isRequired!"]))
                .matches(passwordRegex.regex, passwordRegex.message)
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            confirmPassword: Yup.string()
                .required(localize(["confirm", "password", "isRequired!"]))
                .oneOf([Yup.ref("newPassword"), null], localize(["passwordsMustMatch"]))
                .matches(passwordRegex.regex, passwordRegex.message)
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            oldPassword: Yup.string()
                .required(localize(["Reset.old", "password", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
        });
    }
    const resetPassword = useFormik({
        initialValues: intialState,
        validationSchema: LoginSchema,
        onSubmit: (values, { setErrors, resetForm }) => {
            const decodedUser = SessionStorage.getKey(SessionVariables.AUTH_TOKEN)
                ? jwt(SessionStorage.getKey(SessionVariables.AUTH_TOKEN))
                : undefined;
            let username = "";
            if (decodedUser) {
                username = decodedUser.jti;
            }
            else {
                username = SessionStorage.getKey(SessionVariables.USERNAME);
            }
            let fotgotPasswordToken = getPasswordResetTokenFromUrl();
            const payload = {
                username: username !== null && username !== void 0 ? username : (fotgotPasswordToken ? "" : null),
                oldpassword: values.oldPassword,
                newpassword: values.newPassword,
                forgotPasswordToken: fotgotPasswordToken,
            };
            handleChangePassword(payload, setErrors);
        },
    });
    const getPasswordResetTokenFromUrl = () => {
        var _a;
        const searchParams = new URLSearchParams(window.location.search);
        return (_a = searchParams.get('token')) !== null && _a !== void 0 ? _a : null;
    };
    return (React.createElement("div", { className: "reset-password", "data-test": "resetPasswordForm" },
        React.createElement("h4", { className: "mt-0 mb-2" }, modalTitle),
        React.createElement("p", { className: "text-color-200" }, modalDescription),
        React.createElement("form", { className: `mb-1 ${!hideOldPassword && `customForm`}`, name: "basic", onSubmit: resetPassword.handleSubmit },
            !hideOldPassword ? (React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Reset.old", "password"]), type: "password", name: "oldPassword", value: resetPassword.values.oldPassword, onChange: resetPassword.handleChange, className: `${resetPassword.errors.oldPassword &&
                        resetPassword.touched.oldPassword
                        ? "input-error"
                        : null}` }),
                resetPassword.errors.oldPassword &&
                    resetPassword.touched.oldPassword && (React.createElement("p", { className: "error" }, resetPassword.errors.oldPassword)))) : (React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["Reset.old", "password"]), type: "hidden", name: "oldPassword", value: "Usama", onChange: resetPassword.handleChange, className: `${resetPassword.errors.oldPassword &&
                        resetPassword.touched.oldPassword
                        ? "input-error"
                        : null}` }),
                resetPassword.errors.oldPassword &&
                    resetPassword.touched.oldPassword && (React.createElement("p", { className: "error" }, resetPassword.errors.oldPassword)))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Tooltip, { title: resetPassword.errors.newPassword ||
                        !resetPassword.values.newPassword.match(passwordRegex === null || passwordRegex === void 0 ? void 0 : passwordRegex.regex)
                        ? passwordRegex === null || passwordRegex === void 0 ? void 0 : passwordRegex.message
                        : null, trigger: "hover" },
                    React.createElement(Input, { placeholder: localize(["Reset.new", "password"]), type: "password", name: "newPassword", 
                        // title={passwordRegex?.message}
                        value: resetPassword.values.newPassword, onChange: resetPassword.handleChange, className: `${resetPassword.errors.newPassword &&
                            resetPassword.touched.newPassword
                            ? "input-error"
                            : null}` })),
                resetPassword.errors.newPassword &&
                    resetPassword.touched.newPassword && (React.createElement("p", { className: "error" }, resetPassword.errors.newPassword))),
            React.createElement("div", { className: "form-group" },
                React.createElement(Input, { placeholder: localize(["confirm", "password"]), type: "password", name: "confirmPassword", value: resetPassword.values.confirmPassword, onChange: resetPassword.handleChange, className: `${resetPassword.errors.confirmPassword &&
                        resetPassword.touched.confirmPassword
                        ? "input-error"
                        : null}` }),
                resetPassword.errors.confirmPassword &&
                    resetPassword.touched.confirmPassword && (React.createElement("p", { className: "error" }, resetPassword.errors.confirmPassword))),
            resetPassword.errors["invalid"] && (React.createElement("p", { className: "error" }, resetPassword.errors["invalid"])),
            React.createElement("div", { className: "form-group" },
                React.createElement(Button, { type: "primary", htmlType: "submit" }, localize([
                    hideOldPassword ? "Reset.reset" : "Button.change",
                    "password",
                ]))))));
}
ResetPasswordForm.propTypes = {
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    handleChangePassword: PropTypes.func,
    hideOldPassword: PropTypes.bool,
};
export default ResetPasswordForm;
