var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import RegisterUserForm from "./components/registerUserForm";
import { useLocation, useHistory } from "react-router-dom";
import { TERMS_CONDITION } from "../../../core/constants/termsCondition";
import { UserApi } from "../../../core/api/userApi";
import { notifications } from "../../../layout/components/notifications";
import { ResponseCodeWithMessages } from "../../../core/constants/responseStatusCodes";
import { TelephoneType } from "../../../core/enums/TelephoneType";
import { UserStatus, UserType } from "../../../core/enums/UserStatus";
import { loginRoute } from "../../../core/constants/routesPath";
import localize from "../../../core/utils/translation-files";
import { ErrorCode } from "../../../typings/apiErrorCodes";
import { LookupNames } from "../../../core/constants/lookup";
import { getLookupByListing } from "../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../redux/lookup/actions";
import { connect } from "react-redux";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const RegisterUserContainer = ({ getLookups, titles }) => {
    let query = useQuery();
    let history = useHistory();
    const [initialUser, setInitialUser] = useState({
        id: 0,
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        username: undefined,
        emailAddress: undefined,
        password: undefined,
        confirmPassword: undefined,
        status: UserStatus.ACTIVE,
        companyId: undefined,
        userType: UserType.External,
        telephoneType: TelephoneType.Mobile,
        telephoneNumber: "",
        termsAndConditionKey: TERMS_CONDITION.key,
        termsAndConditionAccepted: false,
        professionId: undefined,
        title: undefined,
        userCompanyRoleDTOs: [],
    });
    useEffect(() => {
        getLookupByListing({
            LookupNames: [LookupNames.LKProfession, LookupNames.LKTitle],
            getLookups,
        });
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (query.get("identifier")) {
                if (initialUser.emailAddress && initialUser.firstName) {
                    return;
                }
                yield UserApi.validateExternalUserURL(query.get("identifier"))
                    .then((res) => {
                    if (res.code === null) {
                        setInitialUser(Object.assign(Object.assign({}, initialUser), { firstName: res.firstName, middleName: res.middleName, lastName: res.lastName, emailAddress: res.emailAddress, professionId: res.professionId, status: res.status, userCompanyRoleDTOs: res.externalUserSiteResponseDTOs, username: res.emailAddress, companyId: res.companyId, title: res.title, id: 0 }));
                    }
                    else if (res.code ===
                        ResponseCodeWithMessages.EXTERNAL_USER_ALREADY_REGISTERED.key) {
                        notifications.error(ResponseCodeWithMessages.EXTERNAL_USER_ALREADY_REGISTERED.value);
                        history.push(loginRoute);
                    }
                    else if (res.code ===
                        ResponseCodeWithMessages.EXTERNAL_USER_DECLINED_REGISTRATION.key) {
                        notifications.error(ResponseCodeWithMessages.EXTERNAL_USER_DECLINED_REGISTRATION
                            .value);
                        history.push(loginRoute);
                    }
                    else if (res.code ===
                        ResponseCodeWithMessages
                            .EXTERNAL_USER_REGISTRATION_LINK_DATA_MISSING.key) {
                        notifications.error(ResponseCodeWithMessages
                            .EXTERNAL_USER_REGISTRATION_LINK_DATA_MISSING.value);
                        history.push(loginRoute);
                    }
                    else if (res.code ===
                        ResponseCodeWithMessages.EXTERNAL_USER_REGISTRATION_LINK_EXPIRED
                            .key) {
                        notifications.error(ResponseCodeWithMessages.EXTERNAL_USER_REGISTRATION_LINK_EXPIRED
                            .value);
                        history.push(loginRoute);
                    }
                    else if (res.code === ResponseCodeWithMessages.EXTERNAL_USER_NOT_FOUND.key) {
                        notifications.error(ResponseCodeWithMessages.EXTERNAL_USER_NOT_FOUND.value);
                        history.push(loginRoute);
                    }
                })
                    .catch(() => { });
            }
            else {
                history.push(loginRoute);
            }
        });
        if (initialUser.emailAddress === undefined && (titles === null || titles === void 0 ? void 0 : titles.length) > 0) {
            fetchData();
        }
    });
    const registerUserHandler = (user) => {
        UserApi.registerExternalUser(user)
            .then((res) => {
            if (res.code === ErrorCode.internalServerError || res.code === ErrorCode.conflictError) {
                notifications.error(res.message);
            }
            else if (user.termsAndConditionAccepted) {
                notifications.success(localize(["Register.userRegistered"]));
                history.push(loginRoute);
            }
            else if (!user.termsAndConditionAccepted) {
                notifications.error(localize(["Register.registerationDeclined"]));
                history.push(loginRoute);
            }
        })
            .catch((err) => {
            // TODO: Show Toast in case of any error
        });
    };
    return (React.createElement("div", null,
        React.createElement(RegisterUserForm, { initialUser: initialUser, RegisterUserHandler: registerUserHandler })));
};
function mapStateTopProps(state) {
    return {
        titles: state.lookups[LookupNames.LKTitle],
    };
}
const mapDispatchToProps = {
    getLookups,
};
export default connect(mapStateTopProps, mapDispatchToProps)(RegisterUserContainer);
